@media only screen and (max-width: 1750px) and (min-width: 1400px) {
    .custom1 .owl-dots {
        position: absolute;
        top: 90%;
        left: 5%;
    }

    .how-we-work {
        margin-left: -70px;
        padding: 0;
    }

    .how-we-work-section .shape-style:before {
        top: 87%;
    }

    .view-btn {
        right: 7%;
    }

    .unique-digital-ideas:before {
        left: -10%;
    }

    .unique-digital-ideas:before {
        left: -10%;
    }

    section.always-happy.gap {
        padding-bottom: 90px;
    }
}

@media (max-width: 1400px) {
    p {
        font-size: 16px;
        line-height: 28px;
    }

    h2 {
        font-size: 45px;
        line-height: 55px;
    }

    .view-btn h5 {
        color: white;
        font-weight: 600;
        font-size: 16px;
    }

    ul.Instagram-img figure.effect-duke {
        width: 80px;
        height: 80px;
    }

    .portfolio-two-list .client-detail li {
        padding: 14px;
    }

    .colin-clickson .social-media li a i {
        height: 45px;
        width: 45px;
        font-size: 15px;
    }

    .portfolio-two-list {
        padding: 30px;
    }

    .location-data.shape-style-hover:hover:before {
        left: 75%;
        top: 86%;
    }

    .cls-left.cls-right h6 {
        display: block;
        font-size: 34px;
        padding-bottom: 8px !important;
        line-height: 22px;
        font-weight: 400;
    }

    .cls-left.cls-right .colin-clickson h5 {
        font-size: 16px;
    }

    .client-detail a {
        font-size: 16px;
    }

    .zoom-slider .owl-item.active.center figure:before {
        width: 80%;
        height: 87%;
    }

    .client-detail h4 {
        font-size: 16px;
    }

    .video-section .heading h2 {
        width: 100%;
    }

    .blog-post-data ul {
        margin: 20px;
    }

    .mobil-img.shape-style-hover:hover:before {
        top: 94.5%;
        left: 84%;
    }

    .testimonials h3 {
        padding-top: 30px;
    }

    .newsletter p {
        padding-top: 36px;
    }

    .owl-theme .owl-nav {
        margin-top: 20px;
    }

    .sidebar-two input[type="text"] {
        font-size: 14px;
    }

    .blog-post-img a {
        top: 20px;
        left: 20px;
    }

    .blog-style-two.blog-three h2 {
        margin-top: -50px;
        transform: translateY(-10px);
    }

    .play p {
        width: 80%;
    }

    .blog-style-two.blog-three p, .blog-style-two p span {
        font-size: 16px;
    }

    .client-help p {
        font-size: 22px;
        line-height: 34px;
    }

    .mobil-img.shape-style-hover figure.effect-duke {
        height: 505px;
    }

    .blog-style-two.blog-three .client-help p {
        font-size: 22px;
        line-height: 36px;
    }

    .create-impactful p {
        font-size: 18px;
        padding-top: 15px !important;
    }

    header.header-style-three.fore .bar-menu svg {
        fill: black;
    }

    .client-detail li {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    section.always-happy.gap {
        background: no-repeat;
        background-size: cover;
    }

    .myslider .owl-dots .owl-dot button span {
        margin-top: 14px;
    }

    .play {
        margin-top: 10px;
    }

    .view-btn i {
        width: 25px;
        height: 25px;
        font-size: 14px;
    }

    .blog-style-two h2 {
        width: 120px;
        height: 120px;
        padding-top: 22px;
    }

    .how-we-work {
        padding-left: 0;
    }

    .clients .owl-nav button i {
        font-size: 16px;
        height: 60px;
        width: 60px;
    }

    .title {
        font-size: 18px;
    }

    .portfolio-detail img {
        width: 100%;
    }

    .sec-btn {
        font-size: 16px;
        height: 60px;
        font-weight: 600;
    }

    header .sec-btn {
        font-size: 16px;
        height: 50px;
        padding: 12px 38px;
        font-weight: 600;
    }

    .planning-make.about-make i {
        top: -20%;
    }

    .planning-make.about-make:before {
        top: -19%;
    }

    ul.quick-links li {
        padding-bottom: 24px;
    }

    .recent-post i {
        font-size: 17px;
    }

    .sidebar {
        padding: 30px;
    }

    .blog-post-data {
        padding: 40px;
    }

    .about-unique .services-style-one {
        padding: 30px;
    }

    .navbar-links li.navbar-dropdown .dropdown a {
        padding-left: 15px;
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 14px;
    }

    .servics-clients .we-help {
        text-align: start;
        padding: 49px;
    }

    .services-img p {
        width: 90%;
    }

    .navbar-links li.navbar-dropdown .dropdown {
        width: 200px;
    }

    .zoom-slider-data h3 {
        font-size: 14px;
        margin-left: 20px;
    }

    .white_content {
        width: 47% !important;
        padding: 70px 55px;
    }

    .navbar-links li.navbar-dropdown .dropdown a {
        padding-left: 23px;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 14px;
    }

    .years-experience h3 {
        font-size: 80px;
        font-weight: 600;
        padding-right: 20px;
        line-height: 0px;
    }

    .years-experience h6 {
        font-size: 16px;
    }

    .menu-inner.ps.ps--active-x.ps--active-y .portfolio-img h3 a {
        line-height: 33px;
        font-size: 26px;
    }

    .unique-solutions > p, .planning-make p {
        width: 81%;
    }

    .unique-solutions .heading h2 {
        width: 90%;
    }

    .pricing-plan-one li {
        font-size: 22px;
        padding: 19px;
    }

    .package h6 {
        padding-top: 15px;
        padding-bottom: 5px;
    }

    .pricing-plan-one.text-style li {
        padding: 20.4px;
    }

    .services-style-one:hover .services-style-one-img:before {
        left: 31%;
        top: 4%;
    }

    .pricing-plan-one a.sec-btn {
        height: 60px;
    }

    .blog-style {
        padding: 84px 30px 30px;
    }

    .package {
        padding: 30px;
    }

    .services-style-one h4 {
        font-size: 22px;
    }

    .team-slider .owl-nav button.owl-prev i, .team-slider .owl-nav button.owl-next i, .photo .owl-nav button.owl-next i, .photo .owl-nav button.owl-prev i {
        height: 45px;
        width: 45px;
        border-radius: 51px;
        font-size: 14px;
        border: 1px solid #7d7d7d;
    }

    .myslider .owl-dots .owl-dot button {
        font-size: 30px;
    }

    .planning-make i {
        padding: 25px;
        margin-right: 40px;
    }

    .planning-make h3 {
        font-size: 24px;
    }

    .unique-digital-ideas:before {
        left: -16%;
    }

    .planning-make svg {
        width: 40px;
    }

    .planning-make {
        margin-top: 30px;
    }

    .featured-img img.featured-one-img {
        width: 241px;
        height: 279px;
    }

    .featured-img img.featured-two-img {
        width: 360px;
        height: 400px;
    }

    .myslider .owl-dots {
        left: -50%;
        top: 14%;
    }

    form.message .nice-select.Advice {
        height: 60px;
    }

    form.message .nice-select:before {
        height: 34px;
        right: 11%;
    }

    form.message .nice-select:after {
        right: 28px;
        top: 46%;
    }

    .expertise input[type="text"] {
        height: 60px;
    }

    .blog-style-two h2 {
        margin-top: -80px;
        transform: translateY(-38px);
    }

    .featured-area-three .view-btn {
        bottom: -15%;
        right: 6%;
    }

    .myslider .owl-nav {
        bottom: -26%;
    }

    .blog-style-two-data > h3 > a {
        padding-top: 5px;
        padding-bottom: 10px;
    }

    .blog-style-two-data a {
        padding-top: 34px;
    }

    ul.logos li img {
        transform: scale(0.7);
    }

    .button-gap {
        margin-top: 0;
    }

    ul.logos li {
        padding: 25px;
    }

    ul.logos li:hover img {
        transform: scale(0.8);
    }

    .myslider .owl-dots .owl-dot button {
        margin-bottom: 25px;
    }

    .featured-area-three .owl-theme .owl-nav.disabled + .owl-dots {
        top: 12%;
    }

    .hero-section-two:after {
        top: 75.5%;
        left: 63%;
    }

    .featured-area-three .view-btn {
        bottom: -6%;
        right: 9%;
    }

    .photo-preview::after {
        padding: 190px;
        top: 2%;
    }

    .marquee_text .portfolio-img img {
        width: 275px;
        height: 310px;
    }

    .laptop img {
        width: 100%;
    }

    .unique-solutions > p {
        padding-bottom: 25px;
    }

    .count-time h2, .count-time span {
        font-size: 62px;
        margin-top: -20px;
    }

    .custom2 .owl-nav {
        bottom: -3%;
    }

    .custom2 .owl-dots {
        left: -32%;
        bottom: -0.5%;
    }

    .count-time p {
        top: 58%;
    }

    .count-time.one {
        margin-top: 34px;
        margin-left: 63px;
    }

    .phone-style svg {
        margin-top: -4px;
        width: 26px;
    }

    .unique-digital-ideas .heading h2 {
        width: 100%;
    }

    .count-time.one:before {
        top: -41%;
    }

    .count-time.one {
        width: 220px;
        height: 220px;
    }

    .category-info h3 a {
        font-size: 23px;
    }

    .heading h2 {
        padding-bottom: 44px;
    }

    .category-info p {
        font-size: 14px;
    }

    .category.one {
        padding-left: 50px;
    }

    ul.client-data li img {
        width: 120px;
    }

    ul.client-data li {
        width: 210px;
        height: 210px;
    }

    .blog-button p {
        width: 100%;
    }

    .count-time {
        width: 200px;
        height: 200px;
    }

    .img-unique.shape-style:before {
        top: 86%;
        left: 62%;
    }

    .planning-make:before {
        height: 25px;
        width: 25px;
        top: -2%;
        font-size: 14px;
    }

    .planning-make:after {
        height: 110px;
        left: 8.5%;
        top: 73%;
        z-index: -1;
    }

    .zoom-slider-data h4 {
        font-size: 20px;
        top: 69%;
        left: -18%;
    }

    .team-flex ul {
        top: 60%;
    }

    .img-team img {
        height: 240px;
    }

    .zoom-slider-data h2 {
        top: 54%;
        left: 2%;
    }

    .how-we-work-section {
        padding-bottom: 0;
    }

    .how-we-work-section .shape-style:before {
        top: 87%;
    }

    .zoom-slider .owl-item > div {
        padding-left: 36px;
    }

    #scroll {
        width: 35px;
        height: 35px;
    }

    #scroll span {
        margin-left: -6px;
        margin-top: -9px;
        border: 6px solid transparent;
        border-bottom-color: #ffffff;
    }

    .zoom-slider .owl-nav {
        bottom: -2%;
    }

    .team-flex {
        padding: 35px;
    }

    .team-flex:hover ul {
        left: 75%;
    }

    .photo-preview .two img {
        left: 72%;
        top: 33%;
    }

    .photo-preview .five img {
        left: 35%;
        top: 44%;
    }

    .five img {
        width: 100px;
    }

    .photo-preview .for img {
        left: 49%;
        top: 101%;
    }

    .blog-style > h3 > a {
        font-size: 24px;
        padding-bottom: 15px;
        line-height: 32px;
        padding-top: 0;
    }

    .blog-style a {
        padding-top: 35px;
    }

    .links li a {
        font-size: 15px;
    }

    .footer-style-one .gap {
        padding: 80px 0;
    }

    .blog-style.shape-style-hover:hover:before {
        left: 75%;
    }

    .footer-style-one {
        padding-bottom: 30px;
    }

    .links li {
        padding-top: 24px;
    }

    .footer-style-one {
        padding-top: 80px;
    }

    .newsletter h4 {
        font-size: 18px;
    }

    .heading h2 {
        width: 70%;
    }

    .testimonials p {
        width: 100%;
        font-size: 22px;
        line-height: 34px;
    }

    .team-flex.shape-style-hover:hover:before {
        left: 81%;
    }

    .services-style-one.nogap {
        margin: 0;
    }

    .logodata .owl-dots {
        display: none;
    }

    .pdd, .blog-style-two.blog-three.page-two .blog-style-three-data {
        padding: 0px 100px;
    }

    .detailmobil h4 {
        font-size: 26px;
    }

    ul.features li {
        font-size: 16px;
    }

    .blog-style-two.blog-three.page-two .blog-style-three-data {
        padding: 0px 50px;
    }

    .blog-style-two.blog-three.page-two .blog-style-three-data h3 a {
        font-size: 40px;
    }

    .what-we-provide.address h5 {
        font-size: 16px;
        line-height: 26px;
    }

    .location-data h3 {
        font-size: 26px;
    }

    .blog-post-data h2 {
        font-size: 38px;
        font-weight: 600;
    }

    .blog-post.shape-style-hover:hover:before {
        left: 87.5%;
    }

    .mobil-img img {
        width: 100%;
    }

    .services-img img {
        width: 92%;
    }

    .button-gap {
        margin-top: 60px;
        margin-bottom: 0;
        line-height: 0;
    }

    .portfolio-img {
        padding-left: 20px;
    }

    .work-man img {
        width: 100%;
    }

    .our-expertise li a {
        font-size: 16px;
    }

    /*.portfolio-page-img-data.video-img img {
    	padding-top: 46px;
	}*/
    .team-man.shape-style-hover img {
        width: 100%;
    }

    .we-help p {
        width: 64%;
    }

    .always-happy .heading.two h2 {
        width: 100%;
    }

    .blog-style-two-data > h3 > a {
        font-size: 24px;
        line-height: 34px;
        margin-top: -38px;
    }

    .input-wrap:before {
        width: 18px;
    }

    .blog-style-two h2 {
        font-size: 40px;
    }

    .blog-style-two-data {
        padding: 40px;
    }

    ul.logos.shape-style:before {
        top: 82%;
        left: 75%;
    }

    .blog-style-two.shape-style-hover:hover:before {
        top: 95%;
        left: 75%;
    }

    .hire .heading h2 {
        width: 58%;
    }

    .hero-section p {
        width: 52%;
    }

    ul.client-data.partner {
        margin-left: 121px;
    }

    .style-design a {
        font-size: 28px;
    }

    .design-agency .heading h2 {
        width: 60%;
    }

    .style-design a {
        margin-left: -10px;
    }

    .always-happy:before {
        display: none;
    }

    .photo-preview::after {
        padding: 180px;
        left: 3.4%;
        top: 5.3%;
    }

    .navbar-links li > a {
        font-size: 16px;
    }

    .navbar-links li a {
        padding: 10px 20px;
    }

    .home-one-featured-area h1 {
        font-size: 62px;
    }

    .custom1 .owl-dots {
        left: 10%;
    }

    .branding-products {
        padding-top: 180px;
        padding-bottom: 139px;
        min-height: 626px;
    }

    .custom1 .owl-dot:before {
        font-size: 12px;
    }

    .footer-style-one.two .Instagram-img img {
        height: 80px;
        width: 80px;
    }

    .custom2 .owl-nav {
        left: -47%;
    }

    .custom2 .owl-dots {
        left: -33%;
    }

    .hero-section-two:before {
        right: -14%;
    }

    .create-impactful h3, .create-impactful h2 {
        font-size: 50px;
        line-height: 60px;
    }

    .hero-section-two-img img.two-img-one {
        width: 360px;
    }

    .hero-section-two-img img.two-img-two {
        height: 372px;
        left: 30%;
    }

    .hero-section-two-img img.two-img-three {
        height: 317px;
        left: 51%;
        top: 14%;
    }

    .box {
        padding: 60px 300px;
    }

    .flex-img h2 {
        margin-bottom: 25px;
    }

    .owl-theme .owl-nav.disabled + .owl-dots {
        margin-top: 0px;
    }

    .view-btn {
        right: 12%;
        bottom: 5%;
    }

    .play p {
        padding-left: 20px;
    }

    .hero-section-two {
        padding: 50px 0;
    }

    .hero-section-two:before {
        right: -15%;
        top: 48%;
    }

    .white_content {
        width: 47% !important;
        padding: 50px 40px;
    }

    .cls-left.cls-right h3 {
        font-size: 30px;
        line-height: 40px;
    }

    .white_content i {
        font-size: 26px;
    }

    .play p {
        font-size: 18px;
    }

    .cls-left.cls-right img {
        height: 236px;
        width: 100%;
        object-fit: cover;
    }

    .cls-left.cls-right {
        padding-right: 40px;
    }

    .accordion-item .content p {
        width: 90%;
    }

    .play svg {
        width: 24px;
    }

    .video-section {
        min-height: 660px;
    }

    .cls-left.cls-right img {
        padding-top: 10px !important;
    }

    .flex-img h2 {
        font-size: 32px;
        line-height: 42px;
    }

    .services-two {
        padding-bottom: 177px;
    }

    form.message input[type="text"], textarea, .box form.message .nice-select.Advice {
        height: 60px;
        font-size: 14px;
    }

    .box form.message .nice-select:before {
        right: 7%;
        height: 32px;
    }

    .box span.current {
        font-size: 14px;
    }

    .box form.message textarea {
        height: 160px;
    }

    .box .close i {
        font-size: 22px;
        height: 45px;
        width: 45px;
        right: 2%;
        top: 5%;
    }

    .behance h2 {
        font-size: 50px;
    }

    .behance li img {
        width: 155px;
        height: 120px;
    }

    .behance.Instagram li img {
        width: 80px;
        height: 80px;
    }

    .what-we-provide h2 {
        font-size: 32px;
    }

    .design-agency .what-we-provide span {
        font-size: 24px;
    }

    .what-we-provide {
        padding-bottom: 30px;
        margin-bottom: 30px;
    }

    .start-footer h2 {
        padding: 50px 0 28px;
        font-size: 50px;
    }

    .start-footer .information-data h6 {
        padding-bottom: 20px;
    }

    .start-footer ul {
        padding: 20px 0;
    }

    .pricing-Plans-style-one {
        padding: 30px;
    }

    .pricing-Plans-style-one li h4 {
        padding-top: 18px;
    }

    .links li {
        padding-top: 18px;
    }

    .pricing-Plans-style-one h3 {
        margin-bottom: 10px;
    }

    .category-info {
        padding: 30px;
    }

    .category.one.shape-style-hover:before {
        top: 74%;
        left: 88%;
    }

    .category.shape-style-hover:before {
        top: 83%;
        left: 78%;
    }

    .footer-style-one.two .no-bottom {
        padding: 80px 0 0 0;
    }

    footer.footer-style-one.two .logo p {
        width: 100%;
    }

    .pricing-Plans-style-one h6 {
        padding-top: 30px;
    }

    button.filter-btn {
        margin-bottom: 0;
    }

    .behance ul li figure.effect-duke {
        width: 80px;
    }
}

@media (max-width: 1199px) {
    .heading h2 {
        width: 86%;
    }

    .count-time.one {
        margin-left: 10px;
    }

    .video-accordion {
        padding: 78px 0;
    }

    .services-style-one {
        margin: 40px;
    }

    .blog-style h2 {
        top: -21%;
    }

    .services-style-one.nogap {
        margin: 0;
    }

    .portfolio-img {
        padding-left: 0px;
    }

    .zoom-slider .owl-item.active.center figure:before {
        width: 86.4%;
        height: 96%;
    }

    .mobile-nav, #mobile-nav.hmburger-menu {
        width: 50%;
    }

    .top-bar-header {
        display: none;
    }

    .zoom-slider-data h4 {
        display: none;
    }

    .unique-solutions .heading h2, .unique-solutions > p, .planning-make p {
        width: 100%;
    }

    .photo-preview .two img {
        left: 87%;
    }

    section.how-we-work-section {
        padding: 0px 9%;
    }

    .img-team img {
        height: 290px;
    }

    .behance ul li figure.effect-duke {
        width: 70px;
        height: 70px;
    }

    .share-post {
        padding: 25px;
    }

    .social-media {
        flex-wrap: wrap;
    }

    li.Instagram {
        margin-top: 15px;
    }

    .services-three .services-style-one {
        padding: 40px;
        margin: 20px 0 0 0;
    }

    .box form.message .nice-select:before {
        right: 11%;
    }

    .myslider .owl-dots {
        left: 2%;
    }

    .we-provide .accordion-item .icon {
        top: 40%;
    }

    .box {
        padding: 60px 200px;
    }

    .myslider.owl-theme .owl-dots .owl-dot span, .owl-theme .owl-dots .owl-dot:hover span {
        margin-right: 10px;
    }

    .featured-area-three .view-btn {
        display: none;
    }

    .photo-preview::before {
        padding: 197.5px;
    }

    .team-slider-threuue.owl-carousel .owl-item.active.center img {
        width: 68%;
        object-fit: cover;
    }

    .awwards {
        padding-left: 20px;
    }

    .myslider .owl-dots .owl-dot button {
        margin-bottom: 28px;
    }

    .featured-img img.featured-two-img {
        width: 281px;
        height: 348px;
    }

    .featured-img img.featured-one-img {
        width: 210px;
        height: 260px;
    }

    .photo-preview .five img {
        left: 44%;
        top: 46%;
    }

    .photo-preview .for img {
        left: 57%;
        top: 105%;
    }

    .years-experience h3 {
        font-size: 70px;
    }

    .unique-solutions {
        padding-top: 40px;
        padding-left: 40px;
    }

    .design-agency .what-we-provide span {
        padding: 0;
    }

    .img-unique.shape-style:before {
        top: 83%;
        left: 56%;
    }

    .behance li img {
        width: 130px;
        height: 110px;
    }

    .behance.Instagram li img {
        width: 70px;
        height: 70px;
    }

    .planning-make:after {
        left: 4.5%;
    }

    .zoom-slider.owl-carousel .owl-item img {
        width: 100%;
    }

    .zoom-slider .owl-item > div {
        padding-left: 4px;
    }

    .team-flex {
        padding: 50px;
    }

    .zoom-slider-data h3 {
        transform: translateY(24px);
    }

    .team-one p {
        width: 97%;
    }

    .team-flex:hover ul {
        left: 88%;
    }

    .photo-preview::after {
        left: 5%;
        top: 6%;
    }

    .blog-style {
        padding: 65px 15px 40px;
    }

    .blog-style a {
        padding-top: 20px;
    }

    .blog-style.shape-style-hover:hover:before {
        left: 70%;
        top: 90%;
    }

    .newsletter h4 {
        font-size: 15px;
    }

    .links li a {
        font-size: 13px;
    }

    .hero-section p {
        width: 61%;
    }

    .blog-style-three-data h3 a {
        font-size: 32px;
    }

    .sidebar {
        padding: 20px;
    }

    .share-post h5 {
        font-size: 17px;
    }

    .comment-data h4 {
        font-size: 20px;
    }

    li.reply-comment {
        margin-left: 50px;
        width: 92%;
    }

    .pdd, .blog-style-two.blog-three.page-two .blog-style-three-data {
        padding: 0px 60px;
    }

    .blog-post-data {
        padding: 25px;
    }

    .portfolio-img img {
        width: 100%;
    }

    .our-expertise li {
        width: 50%;
    }

    .our-expertise li a {
        font-size: 18px;
    }

    .services-style-two-data {
        width: 100%;
        top: 62%;
        left: 13%;
    }

    .services-two {
        padding-bottom: 220px;
    }

    .unique-digital-ideas:before {
        left: -26%;
    }

    .video-section .heading h2 {
        width: 100%;
        font-size: 40px;
    }

    .accordion-item .heading {
        padding: 0.5rem 0 1.5rem 3rem;
    }

    .title {
        font-size: 16px;
        margin-top: 10px;
    }

    .blog-two .heading.two h2, .what-we-provide p,
    .testimonials-img img {
        width: 100%;
    }

    .category .category-info {
        margin-right: 0%;
    }

    .category-info h3 a {
        font-size: 18px;
        line-height: 28px;
    }

    ul.client-data li {
        width: 160px;
        height: 160px;
    }

    ul.client-data li {
        width: 160px;
        height: 160px;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .testimonials-img p {
        font-size: 14px;
    }

    ul.client-data li img {
        padding-top: 0;
    }

    .client:hover a {
        font-size: 14px;
    }

    ul.client-data.partner {
        margin-left: 94px;
    }

    .pricing-Plans-style-one {
        padding: 50px 30px;
    }

    .what-we-provide span {
        padding-right: 24px;
    }

    .what-we-provide h2 {
        font-size: 32px;
        line-height: 42px;
    }

    .blog-style-two-data {
        font-size: 28px;
    }

    .blog-style-two-data > h3 > a {
        font-size: 20px;
        line-height: 30px;
        padding-bottom: 10px;
    }

    ul.logos.shape-style:before {
        top: 89%;
        left: 71%;
    }

    ul.logos li {
        width: 50%;
    }

    .blog-style-two.shape-style-hover:hover:before {
        top: 94.5%;
        left: 69%;
    }

    .pricing-plan-one.text-style {
        display: none;
    }

    .pricing-plan-one {
        margin-bottom: 40px;
    }

    .pricing-plan-one.drak {
        margin-bottom: 0px;
    }

    .pricing-plan-one li h4 {
        display: block;
    }

    .pricing-plan-one li {
        justify-content: start;
    }

    .pricing-plan-one li i {
        padding-right: 30px;
        padding-left: 30px;
    }

    .hamburger-icon, .navbar {
        display: none;
    }

    .home-one-featured-area h1 {
        font-size: 54px;
    }

    .bar-menu svg {
        display: block;
    }

    .hero-section-two-img img.two-img-one {
        width: 340px;
    }

    .hero-section-two-img img.two-img-two {
        height: 340px;
        left: 29%;
        top: 10%;
    }

    .hero-section-two-img img.two-img-three {
        height: 300px;
        left: 45%;
        top: 14%;
    }

    .custom2 .owl-nav {
        left: -47%;
        bottom: -6%;
    }

    .custom2 .owl-dots {
        left: -30%;
        bottom: -3%;
    }

    .hero-section-two:before {
        right: -22%;
    }

    .footer-style-one.two .Instagram-img img {
        height: 67px;
        width: 67px;
    }

    .featured-area-three .view-btn {
        bottom: -5%;
        right: 11%;
    }

    .myslider .owl-nav {
        left: 0;
    }

    video {
        height: 950px !important;
        width: auto;
    }
}

@media (max-width: 993px) {
    .gap {
        padding: 80px 0;
    }

    .count-time.one {
        margin-left: 70px;
    }

    #mobile-nav.hmburger-menu, .mobile-nav.open {
        padding: 40px;
    }

    .mobil-img.shape-style-hover figure.effect-duke {
        height: auto;
    }

    .portfolio-img {
        padding-left: 20px;
    }

    .zoom-slider .owl-item.active.center figure:before {
        width: 100%;
        height: 97.7%;
    }

    .branding-products {
        min-height: 550px;
    }

    .section-portfolio {
        display: none;
    }

    .services-style-one:hover .services-style-one-img:before {
        left: 35%;
    }

    .zoom-slider-data h2 {
        font-size: 70px;
        line-height: 80px;
        top: 80%;
        left: 6%;
    }

    .zoom-slider .owl-item > div {
        padding-right: 1px !important;
    }

    .zoom-slider-data h3 {
        font-size: 20px;
        padding-left: 30px;
    }

    .portfolio-resposive-for {
        display: block;
        padding: 120px 0 0 0;
    }

    .zoom-slider-data h3:before {
        left: 0;
        top: 19%;
    }

    .team-slider-threuue .item {
        align-items: inherit !important;
    }

    .featured-area-for-data img {
        width: 300px;
        height: 200px;
        object-fit: cover;
    }

    .services-img p {
        width: 100%;
    }

    .img-team img {
        height: 260px;
    }

    section.how-we-work-section {
        padding: 0px 12%;
    }

    .work-man {
        margin-top: 40px;
    }

    .behance ul li figure.effect-duke {
        width: 100%;
        height: 120px;
        margin: 10px;
    }

    .behance.Instagram li {
        width: 16.6%;
        margin-bottom: 0px;
        margin-right: 20px;
    }

    .portfolio-page-img .video-img {
        margin-bottom: 0;
    }

    .behance.Instagram li img {
        width: 100%;
        height: 120px;
        padding: 0;
    }

    .mobil-img figure {
        height: 500px;
    }

    .mobil-img figure img {
        object-fit: cover;
    }

    li.Instagram {
        margin: 0;
    }

    .hero-section {
        padding: 200px 0 60px;
    }

    .portfolio-page-img-data .video-img {
        margin-bottom: 0px !important;
        padding: 0 !important;
    }

    .myslider .owl-nav {
        display: none;
    }

    .box {
        padding: 40px 100px;
    }

    .no-top {
        padding-top: 0;
    }

    .progress-bar.progress-bar--1, .unique-digital-ideas .heading {
        margin-left: 0;
    }

    .team-slider-threuue.owl-carousel .owl-item.active.center img {
        width: 54%;
    }

    .phone {
        margin-bottom: 40px;
    }

    .hire .heading h2 {
        width: 90%;
    }

    .myslider .owl-dots {
        top: 4%;
        position: relative;
        left: 0%;
        width: 100%;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .featured-img {
        text-align: end;
        justify-content: center;
        margin-top: 50px;
    }

    .myslider.owl-theme .owl-dots .owl-dot span {
        position: absolute;
        top: 25%;
        margin-left: 65px;
        font-size: 16px;
    }

    .myslider .owl-dots .owl-dot button {
        font-size: 14px;
        margin-bottom: 0 !important;
        margin-top: 60px;
        margin-right: 17px;
    }

    .myslider .owl-dots .owl-dot button {
        font-size: 14px;
        margin-bottom: 0 !important;
        margin-top: 40px;
    }

    .team-slider-threuue .colin-clickson.team-sttle-two {
        padding: 40px;
    }

    form.message input[type="text"] {
        margin-top: 15px;
    }

    form.message {
        margin-top: 20px;
    }

    .no-bottom {
        padding-bottom: 0;
    }

    .img-unique figure.effect-duke {
        margin-top: 1.5rem;
    }

    .footer-style-one.two .newsletter {
        margin-bottom: 40px;
    }

    .unique-solutions {
        padding-left: 0;
    }

    .behance li img {
        width: 100%;
        height: 160px;
        margin-bottom: 30px;
        padding-right: 8px;
    }

    .featured-img img.featured-one-img {
        width: 100%;
        height: 250px;
    }

    .featured-img img.featured-two-img {
        width: 100%;
        height: 350px;
    }

    .shape-style:before, .shape-style-hover:before {
        top: -12%;
        left: 65%;
    }

    .photo-preview {
        display: none;
    }

    .expertise > p {
        width: 87%;
    }

    .video-img {
        margin-bottom: 60px;
    }

    .planning-make:after {
        left: 6.5%;
    }

    .team-flex {
        padding: 40px;
    }

    .team-flex:hover ul {
        left: 79%;
    }

    .awwards {
        padding-left: 0px;
        text-align: center;
        margin-bottom: 25px;
        border-right: 0px solid #c5c5c5;
        border-bottom: 1px solid #c5c5c5;
        padding-bottom: 25px;
    }

    .error-page {
        padding-top: 200px;
    }

    .awwards.end {
        padding: 0;
        border-right: 0;
        border: 0;
        margin: 0;
    }

    .testimonials p {
        width: 92%;
        font-size: 20px;
        line-height: 32px;
    }

    .photo-preview .five img {
        left: 42%;
        top: 44%;
    }

    .photo-preview .for img {
        left: 64%;
        top: 101%;
    }

    .photo-preview .two img {
        left: 86%;
        top: 33%;
    }

    .blog-style {
        padding: 70px 30px 42px;
        margin-bottom: 30px;
    }

    .newsletter h4 {
        font-size: 18px;
    }

    .links li a {
        font-size: 16px;
    }

    .newsletter form {
        margin-bottom: 40px;
    }

    .sidebar {
        padding: 40px;
    }

    .hero-section p {
        width: 83%;
    }

    form.leave textarea {
        margin-bottom: 30px;
    }

    .pdd, .blog-style-two.blog-three.page-two .blog-style-three-data {
        padding: 0px;
    }

    .blog-style-two.blog-three.page-two .blog-style-three-data h3 a {
        width: 100%;
    }

    .blog-style-two.blog-three.page-two .blog-style-three-data h3 a {
        font-size: 32px;
    }

    form.content-form {
        margin-bottom: 60px;
    }

    .map iframe {
        height: 400px;
    }

    .pagination {
        margin-bottom: 60px;
    }

    .mobil-img {
        margin-top: 40px;
    }

    .portfolio-two-list {
        display: none;
    }

    .our-expertise li {
        padding: 30px;
    }

    .our-expertise li a {
        font-size: 16px;
    }

    .shape-style:before, .shape-style-hover:before {
        top: 52%;
        left: 60%;
    }

    .accordion {
        margin-bottom: 60px;
    }

    .team-man.shape-style-hover img {
        margin-top: 40px;
    }

    .our-expertise-time {
        margin-top: 40px;
    }

    .services-style-two-data {
        width: 100%;
        left: 45px;
        position: relative;
        margin-top: -69px;
        border: 1px solid #f1f1f1;
        margin-bottom: 40px;
    }

    .services-two {
        padding-bottom: 0;
    }

    .unique-digital-ideas:before {
        display: none;
    }

    .unique-digital-ideas .heading h2 {
        width: 100%;
        padding-bottom: 60px;
    }

    .unique-digital-ideas .heading {
        margin-top: 60px;
    }

    .video-play {
        display: none;
    }

    .video-section {
        min-height: auto;
    }

    .video-accordion {
        padding: 0;
    }

    .video-accordion {
        padding-top: 80px;
    }

    .title {
        font-size: 20px;
    }

    .category-info {
        padding: 20px;
    }

    .category-info a {
        font-size: 10px;
    }

    .blog-button {
        padding-bottom: 60px;
    }

    ul.client-data.partner {
        display: none;
    }

    ul.client-data li {
        margin-right: 10px;
    }

    .pricing-Plans-style-one {
        margin-bottom: 40px;
    }

    .client-and-sponsor .heading h2 {
        width: 100%;
    }

    .count-time span {
        font-size: 70px;
    }

    .blog-style-two {
        margin-bottom: 25px;
    }

    .blog-style-two.end {
        margin-bottom: 0px;
    }

    ul.logos li {
        width: 33%;
    }

    ul.logos.shape-style:before {
        top: 84%;
        left: 72%;
    }

    figure.effect-duke {
        margin-bottom: 20px;
    }

    .testimonials-img img {
        width: auto;
    }

    .blog-style-two.shape-style-hover:hover:before {
        top: 94.5%;
        left: 73%;
    }

    .hero-section-two:after, .custom2 .owl-nav,
    .hero-section-two-img img.two-img-two,
    .hero-section-two-img img.two-img-three {
        display: none;
    }

    .custom2 .owl-dots {
        left: 0%;
        bottom: 0%;
        position: relative;
        margin-top: 40px;
    }

    .hero-section-two-img img.two-img-one {
        width: 100%;
        left: 0;
        top: 0;
        height: auto;
        margin-top: 40px;
        margin-left: -12px;
    }

    .services-style-two figure:before {
        display: none;
    }

    .custom1 .owl-dots {
        left: 15%;
        top: 85%;
    }

    .custom1 .owl-dots {
        left: 15%;
    }

    .how-we-work-section {
        padding-bottom: 0;
    }

    .cols.three .tile, .cols.four .tile {
        width: 49%;
    }

    .section-portfolio {
        padding-bottom: 210px;
    }

    .zoom-slider .owl-item > div {
        padding-left: 0px;
        padding-right: 0;
    }

    .awwards img {
        padding-bottom: 15px;
    }
}

@media (max-width: 768px) {
    .heading h2 {
        width: 100%;
        font-size: 36px;
        line-height: 46px;
    }

    .information-data {
        margin-bottom: 20px;
    }

    .input-wrap:before {
        display: none;
    }

    .count-time.one {
        margin-left: 30px;
    }

    .portfolio-img {
        padding-left: 0px;
    }

    .owl-theme .owl-nav {
        margin-top: 0px;
        position: relative;
        float: left;
    }

    .portfolio-img h3 a {
        font-size: 26px;
    }

    .branding-products {
        min-height: 568px;
    }

    .blog-style-three-data .blog-post-data ul {
        flex-wrap: wrap;
    }

    .featured-area-for-data img {
        width: 280px;
        height: 180px;
    }

    .services-style-one:hover .services-style-one-img:before {
        left: 40%;
    }

    .featured-area-for-data a {
        font-size: 30px;
    }

    ul.client-data {
        justify-content: center;
    }

    .information-data h3 {
        padding-top: 30px;
    }

    .clients .owl-nav {
        top: 85%;
    }

    .hero-section {
        padding: 160px 0 60px;
    }

    .servics-clients .we-help p {
        font-size: 22px;
        line-height: 32px;
    }

    .servics-clients .we-help {
        text-align: start;
        padding: 40px;
    }

    .about-unique .services-style-one {
        padding: 40px;
        margin: 0;
        margin-bottom: 20px;
    }

    .count-time p {
        font-size: 14px;
    }

    .custom1 .owl-dots {
        left: 17%;
    }

    .box {
        padding: 20px 80px;
    }

    .flex-img h2 {
        font-size: 24px;
        line-height: 30px;
    }

    .featured-img img.featured-two-img {
        width: 94%;
        height: 250px;
    }

    .myslider.owl-theme .owl-dots .owl-dot span {
        position: relative;
        margin-left: 20px;
        font-size: 16px;
    }

    .myslider.owl-theme .owl-dots .owl-dot span {
        background-color: transparent;
        width: 16px;
        height: 1px;
        margin-right: 1px;
    }

    .extras.view-btn {
        display: none;
    }

    .team-slider-threuue.owl-carousel .owl-item.active.center img {
        width: 43%;
    }

    .team-slider-threuue .colin-clickson.team-sttle-two {
        margin: auto;
    }

    .behance li img {
        height: 140px;
    }

    .behance.Instagram li {
        width: 20%;
        margin-bottom: 0px;
    }

    .footer-style-one.two .Instagram-img img {
        height: 100px;
        width: 100px;
    }

    .zoom-slider-data h2 {
        top: 73%;
        left: 8%;
    }

    .phone {
        margin-bottom: 40px;
    }

    .progress-bar__value--1.js-animated {
        margin-right: -5px;
    }

    ul.logos li {
        width: 50%;
    }

    .category {
        flex-wrap: wrap;
    }

    .category.one {
        padding-left: 0px;
    }

    .mobile-nav, #mobile-nav.hmburger-menu {
        width: 70%;
    }

    .category.one .category-info {
        width: 100%;
        margin-left: 15px;
        margin-top: -25px;
    }

    .category .category-info {
        width: 100%;
        margin-left: 15px;
        margin-top: -25px;
    }

    .title {
        font-size: 18px;
    }

    .heading p {
        font-size: 14px;
    }

    .planning-make:after {
        left: 8.5%;
    }

    .portfolio-img h3 {
        font-size: 24px;
    }

    .zoom-slider-data h4 {
        display: none;
    }

    .zoom-slider-data h3 {
        padding-left: 20px;
    }

    .team-slider.owl-carousel .owl-item img {
        height: 200px;
        width: 200px;
        position: relative;
    }

    .team-flex {
        width: 97%;
        margin: 0;
    }

    .team-flex:hover ul {
        left: 62%;
        top: 50%;
    }

    .newsletter form {
        margin-bottom: 0;
    }

    .newsletter {
        margin-top: 40px;
    }

    .error-page h2 {
        font-size: 200px;
        line-height: 150px;
    }

    .error-page h3 {
        font-size: 38px;
    }

    .error-page {
        padding-top: 160px;
    }

    .hero-section p {
        width: 100%;
    }

    .hero-section h2 {
        font-size: 60px;
    }

    ul.features li {
        font-size: 16px;
    }

    .blog-style-two.blog-three .client-help p {
        font-size: 20px;
        line-height: 34px;
    }

    .social-media {
        display: flex;
    }

    .willimes-marko img {
        position: relative;
        left: 0;
        margin-bottom: 20px;
    }

    .willimes-marko {
        margin-top: 40px;
        padding: 25px;
        position: relative;
        width: 100%;
        margin-left: 0px;
        flex-wrap: wrap;
    }

    .social-media li a i {
        display: none;
    }

    .comment li {
        flex-wrap: wrap;
    }

    .blog-style-three-data .blog-post-data ul li h6 {
        font-size: 14px;
    }

    .map iframe {
        height: 360px;
    }

    .heading.two h2 {
        font-size: 30px;
    }

    .blog-post-data h2 {
        font-size: 30px;
        line-height: 40px;
    }

    .blog-post-data ul li h6 {
        font-size: 12px;
    }

    .our-expertise li {
        width: 100%;
    }

    .colin-clickson {
        border: 0;
        margin-bottom: 40px;
    }

    .blog-style-two-data > h3 > a {
        font-size: 30px;
        line-height: 40px;
    }

    ul.logos.shape-style:before {
        top: 89%;
        left: 66%;
    }

    .subscribe-email {
        flex-wrap: wrap;
    }

    .subscribe-email > img {
        padding-right: 0;
        padding-bottom: 30px;
    }

    .expertise > p {
        width: 100%;
    }

    .blog-style-two.shape-style-hover:hover:before {
        top: 95.5%;
        left: 83%;
    }

    .pricing-plan-one.drak {
        margin-bottom: 0px;
        margin-top: 0px;
    }

    .pricing-plan-one {
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .home-one-featured-area h1 {
        font-size: 50px;
    }

    .play p {
        font-size: 16px;
        padding-left: 24px;
        width: 80%;
    }

    .accordion-item .content p {
        margin-left: 0;
        width: 100%;
        text-align: initial;
        margin-bottom: 30px;
    }

    .myslider .owl-dots .owl-dot button {
        margin-top: 10px;
    }

    .featured-img {
        margin-bottom: 40px;
    }

    .button-gap {
        margin-top: 30px;
    }

    .owl-theme .owl-nav.disabled + .owl-dots {
        margin-top: 24px;
    }

    .mobile-nav > ul > li > a {
        font-size: 26px;
    }

    .progress-bar.progress-bar--1 {
        margin-bottom: 60px;
    }
}

@media (max-width: 576px) {
    .zoom-slider .owl-nav, .shape-style:before {
        display: none;
    }

    .newsletter p {
        padding-top: 20px;
    }

    .client-and-sponsor.about-client {
        padding-top: 0;
    }

    .unique-digital-ideas .heading h2 {
        width: 100%;
        padding-bottom: 30px;
    }

    .links li {
        padding-top: 14px;
    }

    .links {
        padding-top: 10px;
    }

    .pricing-plan-one li i {
        padding-left: 13px;
        padding-right: 13px;
    }

    .services-two {
        padding-bottom: 0;
    }

    .pricing-plan-one {
        margin-left: 30px;
        margin-right: 30px;
    }

    .portfolio-detail {
        padding-left: 10px;
    }

    .services-style-one {
        margin: 20px;
    }

    .blog-style-two-data {
        padding: 30px;
    }

    .services-style-one.nogap {
        margin-top: 20PX;
    }

    .hero-section-two:before {
        display: none;
    }

    .create-impactful p {
        padding: 10px 4px;
    }

    ul.client-data li {
        margin-bottom: 10px;
    }

    .branding-products {
        min-height: 617px;
    }

    .portfolio-img h3 a {
        font-size: 30px;
        line-height: 40px;
        padding-top: 0;
    }

    section.how-we-work-section {
        padding: 0px 0%;
    }

    ul.logos.shape-style:before {
        display: none;
    }

    .featured-area-for-data img {
        width: 250px;
        height: 150px;
    }

    .featured-area-for-data a {
        font-size: 26px;
    }

    .portfolio-page-img-data {
        margin-bottom: 20px;
    }

    .gap {
        padding: 50px 0;
    }

    .no-top {
        padding-top: 0;
    }

    .no-bottom {
        padding-bottom: 0;
    }

    .behance ul li {
        margin-top: 10px;
    }

    .clients .owl-nav {
        display: none;
    }

    .box {
        padding: 20px 60px;
    }

    .we-help p {
        width: 100%;
        font-size: 20px;
    }

    .we-help p {
        padding-top: 20px;
    }

    .heading h2 {
        padding-bottom: 26px;
    }

    .box .close i {
        height: 30px;
        width: 30px;
        font-size: 14px;
    }

    .box form.message .nice-select:before {
        right: 15%;
    }

    .shape-style-hover:before {
        display: none;
    }

    form.message {
        margin-top: 0px;
    }

    .blog-style-two h2 {
        transform: translateY(-34px);
    }

    .hire .heading h2 {
        width: 100%;
    }

    .blog-style-two-data > h3 > a {
        margin-top: -30px;
    }

    .custom1 .owl-dots {
        left: 7%;
    }

    .team-slider-threuue .colin-clickson.team-sttle-two {
        padding: 30px;
    }

    .zoom-slider-data h2 {
        top: 78%;
        font-size: 50px;
        line-height: 60px;
    }

    .cols.three .tile, .cols.four .tile {
        width: 100%;
    }

    .create-impactful h3, .create-impactful h2 {
        font-size: 44px;
        line-height: 60px;
    }

    .footer-style-one.two p.footer {
        text-align: center;
    }

    .behance.Instagram li img {
        height: 130px;
    }

    .category.shape-style-hover:before {
        display: none;
    }

    .start-footer h2 {
        padding: 40px 0 20px;
        font-size: 38px;
    }

    .start-footer .information-data a {
        font-size: 26px;
    }

    .team-one h4 {
        font-size: 24px;
    }

    .mobile-nav, #mobile-nav.hmburger-menu {
        width: 100%;
    }

    .count-time span {
        font-size: 50px;
    }

    .count-time h2 {
        font-size: 60px;
    }

    figure.effect-duke {
        margin-bottom: 0px;
    }

    .expertise input[type="text"] {
        width: 100%;
        margin-bottom: 10px;
    }

    .count-time.one {
        width: 180px;
        height: 180px;
    }

    .count-time.one:before {
        height: 60px;
        width: 60px;
    }

    .section-portfolio {
        padding-bottom: 320px;
    }

    .count-time {
        width: 160px;
        height: 160px;
    }

    .team-slider.owl-carousel .owl-item img {
        width: 100%;
        height: auto;
    }

    .planning-make img {
        margin-right: 0;
    }

    .planning-make h3 {
        padding-top: 20px;
    }

    .planning-make:after {
        display: none;
    }

    .planning-make {
        flex-wrap: wrap;
    }

    .team-slider .owl-nav button.owl-prev i,
    .team-slider .owl-nav button.owl-next i,
    .photo .owl-nav button.owl-next i,
    .photo .owl-nav button.owl-prev i {
        height: 35px;
        width: 35px;
        font-size: 12px;
    }

    .team-slider .owl-nav {
        top: -32%;
        left: 37%;
    }

    .willimes-marko p {
        width: 100%;
    }

    .blog-style-three-data .blog-post-data ul {
        flex-wrap: wrap;
    }

    .blog-style-two.blog-three.page-two .blog-style-three-data h3 a {
        font-size: 28px;
    }

    ul.pagination li.prev {
        width: 70px;
        height: 50px;
    }

    ul.pagination li {
        width: 50px;
        height: 50px;
    }

    .blog-post-data ul {
        flex-wrap: wrap;
        margin: 0;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .blog-post-data ul li {
        padding-bottom: 6px;
    }

    .mobil-img.shape-style-hover:hover:before {
        display: none;
    }

    .client-detail a {
        font-size: 16px;
    }

    .portrait-camra img.img-gap {
        width: 160px;
    }

    .blog-style-two.shape-style-hover:hover:before {
        left: 76%;
    }

    .unique-digital-img {
        display: flex;
        padding: 0;
        width: 100%;
        margin-bottom: 30px;
    }

    .team-slider .owl-nav {
        top: 0;
        left: 0;
        position: relative;
    }

    .hero-section-two-img {
        margin-left: 20px;
    }

    .behance.Instagram h3 a {
        margin-top: 30px;
    }

    .design-agency .heading h2 {
        width: 100%;
    }

    li.reply-comment {
        margin-left: 31px;
        width: 92%;
    }

    .team-slider .owl-nav {
        margin-top: 40px;
    }

    button.filter-btn {
        margin-bottom: 15px;
    }

    .behance.Instagram {
        margin-top: 50px;
    }

    .behance h2 {
        font-size: 36px;
    }

    .pagination {
        flex-wrap: wrap;
        margin-top: 20px;
    }

    .comment li {
        flex-wrap: wrap;
        margin: 0;
        margin-top: 25px;
    }

    .comment li img {
        left: 0;
    }
}

@media (max-width: 500px) {
    .what-we-provide h2 {
        font-size: 28px;
        line-height: 38px;
    }

    .zoom-slider .owl-item.active.center figure:before {
        height: 96.7%;
    }

    .what-we-provide h2 {
        font-size: 28px;
        line-height: 38px;
    }

    .behance li img {
        height: 120px;
    }

    form.message input[type="text"], textarea, .box form.message .nice-select.Advice {
        height: 50px;
        font-size: 12px;
    }

    .box form.message textarea {
        height: 130px;
    }
}

@media (max-width: 480px) {
    .heading h2 {
        width: 100%;
        font-size: 34px;
        line-height: 44px;
        padding-top: 20px;
    }

    .detailmobil h4 {
        font-size: 20px;
    }

    .comment-data {
        padding: 0;
        padding: 20px 0;
    }

    .comment-data p {
        padding-top: 10px !important;
    }

    .comment-data span {
        font-size: 12px;
    }

    .comment-data h4 {
        font-size: 18px;
    }

    .detailmobil {
        padding-top: 10px;
    }

    .about-unique .services-style-one {
        padding: 30px;
    }

    .about-unique .unique-solutions {
        padding-top: 0;
    }

    .behance h2 {
        font-size: 30px;
    }

    .behance h2 {
        margin-bottom: 10px;
    }

    .behance.Instagram h3 a {
        margin-top: 10px;
        font-size: 20px;
    }

    .pricing-Plans-style-one li i {
        font-size: 20px;
        padding-right: 10px;
    }

    .how-we-work h2 {
        FONT-SIZE: 30px;
    }

    .zoom-slider-data h3 {
        padding-left: 30px;
    }

    .services-style-one:hover .services-style-one-img:before {
        left: 38%;
    }

    .featured-area-for-data img {
        width: 200px;
        height: 120px;
    }

    .featured-area-for-data a {
        font-size: 24px;
    }

    .hero-section h2 {
        font-size: 40px;
    }

    .behance ul li figure.effect-duke {

        height: 170px;
    }

    .behance.Instagram li img {
        height: 170px;
    }

    footer.three img {
        display: none;
    }

    .hero-section {
        padding: 120px 0 40px;
    }

    .heading img {
        padding-bottom: 20px;
    }

    .featured-img img.featured-one-img {
        margin-right: 0;
    }

    .count-time.one {
        margin-left: 0px;
    }

    .count-time, .count-time.one {
        width: 200px;
        height: 200px;
    }

    form.message .nice-select:before {
        right: 13%;
    }

    .footer-style-one.two .newsletter {
        margin-bottom: 0px;
    }

    .featured-img img.featured-two-img {
        display: none;
    }

    .footer-style-one.two ul.links li {
        width: 100%;
    }

    .team-slider-threuue .item {
        flex-wrap: wrap;
    }

    .featured-img img.featured-one-img {
        width: 100%;
        height: auto;
    }

    .team-slider-threuue.owl-carousel .owl-item.active.center img {
        width: 100%;
    }

    .title {
        font-size: 16px;
    }

    .hero-section ul li a {
        font-size: 12px;
    }

    .custom2.owl-theme .owl-dots .owl-dot span {
        width: 70px;
    }

    .behance li img {
        height: 120px;
    }

    .footer-style-one.two .Instagram-img img {
        height: 80px;
        width: 80px;
    }

    .what-we-provide h2 {
        font-size: 24px;
        line-height: 32px;
    }

    .design-agency .what-we-provide span {
        font-size: 20px;
    }

    .what-we-provide.address h2 a {
        font-size: 24px;
    }

    .heading p {
        font-size: 18px;
        width: 100%;
        letter-spacing: 1px;
        display: inline;
    }

    .information-data a {
        font-size: 24px;
    }

    .error-page h3 {
        font-size: 34px;
    }

    .error-page h2 {
        font-size: 170px;
        line-height: 130px;
    }

    .error-page p {
        font-size: 16px;
    }

    .hero-section h2 {
        font-size: 50px;
    }

    .blog-style-three-data h3 a {
        font-size: 26px;
    }

    .client-help {
        padding: 30px;
    }

    .share-post {
        flex-wrap: wrap;
    }

    .share-post h5 {
        padding-bottom: 20px;
    }

    .blog-style-two h2 {
        font-size: 35px;
        width: 110px;
        height: 110px;
        padding-top: 16px;
    }

    .blog-style-three-data ul li h6 {
        font-size: 16px;
    }

    ul.ks-cboxtags li label {
        font-size: 14px;
    }

    .heading.two h2 {
        font-size: 28px;
    }

    .blog-style-two-data > h3 > a {
        font-size: 28px;
        line-height: 38px;
    }

    .blog-style-two.shape-style-hover:hover:before {
        left: 72%;
    }

    ul.logos li {
        width: 100%;
    }

    .team-flex {
        flex-wrap: wrap;
        padding-bottom: 0;
    }

    .img-team {
        margin-top: 70px;
        width: 100%;
    }

    .team-flex:hover ul {
        left: 32%;
        top: 81%;
    }

    .team-flex.shape-style-hover:before {
        display: none;
    }

    .img-team img {
        right: 0;
        left: 0;
    }

    ul.features li.end {
        padding-bottom: 20px;
    }
}

@media (max-width: 450px) {
    .heading.two h2 {
        font-size: 30px;
    }

    .video-img i {
        height: 80px;
        width: 80px;
    }

    .client-help p {
        font-size: 18px;
        line-height: 30px;
    }

    .mobile-app h2 {
        font-size: 40px;
        padding-top: 10px;
    }

    .portfolio-page-img-data.video-img i {
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%);
    }

    .team-flex {
        width: 96%;
    }

    .zoom-slider .owl-item.active.center figure:before {
        height: 97.3%;
    }

    .blog-style > h3 > a {
        font-size: 20px;
    }

    .clients.servics-clients .owl-dots {
        display: none;
    }

    .zoom-slider-data h2 {
        font-size: 36px;
        line-height: 46px;
    }

    .zoom-slider-data h3 {
        font-size: 14px;
    }

    #mobile-nav.hmburger-menu, .mobile-nav.open {
        width: 100%;
        z-index: 1111;
        background-color: black;
    }

    .home-one-featured-area h1 {
        font-size: 42px;
    }

    .servics-clients .we-help p {
        font-size: 18px;
        line-height: 28px;
    }

    .servics-clients .we-help h4 {
        margin-top: 18px;
    }

    .d-flex-service {
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }

    .start-footer .information-data a {
        font-size: 22px;
    }

    ul.Instagram-img li {
        margin-bottom: 10px;
    }

    .footer-style-one.two .Instagram-img img {
        height: 120px;
        width: 120px;
    }

    ul.features li {
        font-size: 14px;
    }

    .blog-post-data h2 {
        font-size: 22px;
        line-height: 32px;
    }

    ul.pagination li.prev {
        width: 60px;
        height: 40px;
    }

    ul.pagination li {
        width: 40px;
        height: 40px;
    }

    ul.pagination li a {
        font-size: 14px;
    }

    .behance.Instagram li {
        width: 44%;
    }

    .behance li {
        width: 100%;
    }

    .behance li img {
        height: 220px;
        margin-bottom: 10px;
    }

    .behance.Instagram li img {
        height: 170px;
    }

    .design-agency .what-we-provide {
        display: block;
    }

    .design-agency .what-we-provide span {
        margin-top: 15px;
        display: block;
    }

    .start-footer ul {
        padding-top: 0;
    }

    .phone {
        margin-bottom: 25px;
    }
}

@media (max-width: 410px) {
    .heading h2 {
        width: 100%;
        font-size: 30px;
        line-height: 40px;
        padding-top: 16px;
    }

    .team-flex:hover ul {
        left: 26%;
        top: 81%;
    }

    .zoom-slider .owl-item.active.center figure:before {
        height: 97%;
    }

    .featured-area-for-data img {
        width: 200px;
        height: 120px;
    }

    .blog-style-three-data ul {
        flex-wrap: wrap;
    }

    .featured-area-for-data a {
        font-size: 24px;
    }

    .featured-area-for-data img {
        width: 170px;
        height: 100px;
    }

    .behance.Instagram li img {
        height: 170px;
    }

    .hero-section p {
        font-size: 18px;
    }

    .hero-section h2 {
        font-size: 40px;
    }

    .footer-style-one .gap {
        padding: 0;
    }

    .we-help p {
        font-size: 18px;
    }

    .box form.message .nice-select:before {
        right: 21%;
    }

    p.footer {
        margin-top: 40px;
    }

    .flex-img h2 {
        font-size: 22px;
        line-height: 30px;
    }

    .gap {
        padding: 60px 0;
    }

    .no-top {
        padding-top: 0;
    }

    .no-bottom {
        padding-bottom: 0;
    }

    .share-post {
        padding: 20px;
    }

    .create-impactful p {
        font-size: 18px;
    }

    .video-section .heading h2 {
        width: 100%;
        font-size: 36px;
        line-height: 46px;
    }

    .count-time p {
        font-size: 12px;
    }

    .create-impactful h3, .create-impactful h2 {
        font-size: 36px;
        line-height: 46px;
    }

    .myslider .owl-dots {
        justify-content: flex-start;
    }

    .myslider .owl-dots .owl-dot button {
        font-size: 18px;
    }

    form.message .nice-select:before {
        right: 15%;
    }

    .zoom-slider .owl-dots {
        padding-top: 50px;
    }

    .footer-style-one.two .Instagram-img img {
        height: 100px;
        width: 100px;
    }

    .zoom-slider-data h2 {
        top: 76%;
    }

    .sec-btn {
        font-size: 14px;
    }

    .blog-style-two.shape-style-hover:hover:before {
        left: 59%;
        top: 94%;
    }

    .blog-style-two-data > h3 > a {
        font-size: 24px;
        line-height: 34px;
    }

    .what-we-provide h2 {
        font-size: 28px;
        line-height: 38px;
    }

    .count-time h2 {
        font-size: 50px;
    }

    .count-time.one:before {
        top: -39%;
    }

    .count-time {
        width: 140px;
        height: 140px;
    }

    .count-time.one {
        width: 155px;
        height: 155px;
    }

    .portrait-camra img.img-gap {
        width: 120px;
    }

    .blog-style-three-data ul li h6 {
        font-size: 14px;
    }

    .sidebar {
        padding: 30px;
    }

    .recent-post a {
        font-size: 16px;
    }

    .comment h2, .comment h1 {
        font-size: 32px;
    }

    .planning-make h3 {
        font-size: 24px;
        padding-top: 0;
    }

    .services-style-one h4 {
        font-weight: 600;
        padding: 20px 0 15px;
        font-size: 20px;
    }

    .heading p {
        font-size: 16px;
        border-bottom: 2px solid #1870BD
    }

    .error-page h3 {
        font-size: 28px;
    }

    .error-page {
        padding-top: 120px;
    }

    .what-we-provide.address h2 a {
        font-size: 22px;
    }

    .what-we-provide.address h2 {
        font-size: 22px;
    }

    .services-style-pag h2 {
        padding-top: 34px;
        font-size: 34px;
    }
}

@media (max-width: 375px) {
    .information-data a {
        font-size: 22px;
    }

    .footer-style-one.two .Instagram-img img {
        height: 75px;
        width: 75px;
    }

    .title {
        font-size: 15px;
        margin-top: 14px;
    }

    .services-style-one:hover .services-style-one-img:before {
        left: 35%;
    }

    .create-impactful p {
        font-size: 16px;
    }

    .featured-area-for-data a {
        font-size: 22px;
    }

    .featured-area-for-data img {
        width: 150px;
        height: 100px;
    }

    .pagination {
        flex-wrap: wrap;
    }

    .hero-section ul {
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 2200px) and (min-width: 1200px) {
    .team-slider-threuue .colin-clickson.team-sttle-two p {
        padding-bottom: 10px;
    }

    .team-slider-threuue .owl-item.active.center {
        width: 803px !important;
        z-index: 99999;
        margin-top: 15px;
        opacity: 1;
    }

    .team-slider-threuue.owl-carousel .owl-item img {
        display: block;
        width: 100%;
    }

    .team-slider-threuue .owl-item.active {
        width: 285px !important;
        opacity: .5;
    }

    .team-slider-threuue .colin-clickson.team-sttle-two {
        visibility: hidden;
    }

    .team-slider-threuue .owl-item.active.center .colin-clickson.team-sttle-two {
        visibility: visible;
    }

    .team-slider-threuue .owl-item.active.center .item {
        box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 43%);
        -webkit-box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 43%);
        -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.43);
        background-color: white;
    }

    .team-slider-threuue .owl-item.active {
        margin-left: 1px;
    }

    .team-slider-threuue {
        height: 660px;
    }

    .hire .button-gap {
        margin-top: 40px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) {
    .team-slider-threuue .owl-item.active.center {
        width: 722px !important;
    }

    .team-slider-threuue.owl-carousel .owl-item.active.center img {
        width: 74%;
    }

    .team-slider-threuue .colin-clickson.team-sttle-two {
        padding: 31px;
    }

    .team-slider-threuue .owl-item.active {
        width: 200px !important;
        opacity: .5;
    }

    .hire .button-gap {
        margin-top: 0px;
    }
}
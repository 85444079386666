body {
    overflow-x: hidden !important;
    font-family: "Mont", sans-serif;
}

body.menu-layer.page-loaded {
    overflow-x: hidden !important;
}

.video-section {
    overflow-x: hidden !important;
}

*,
p, h1, h2, h3, h4, h5, h6,
figure {
    padding: 0;
    margin: 0;
}

section {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Mont", sans-serif;
    color: black;
}

a {
    font-family: "Mont", sans-serif;
    display: block;
    color: black;
    text-decoration: none;
}

.gap {
    padding: 120px 0;
}

.no-top {
    padding-top: 0;
}

.no-bottom {
    padding-bottom: 0;
}

ul {
    padding: 0;
    margin: 0;
}

li {
    display: block;
}

figure {
    overflow: hidden;
}

h2 {
    font-size: 50px;
    line-height: 60px;
}

h6 {
    font-size: 18px;
    line-height: 28px;
}

p {
    font-size: 18px;
    color: #555;
    line-height: 30px;

}

/* 1. navbar */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: -1;
    opacity: 0;
    transition: 1s all;
}

.navbar {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
}

.navbar-logo {
    color: #ff3f34;
    text-decoration: none;
    font-size: 25px;
    padding: 0px 20px;
}

.navbar-links {
    list-style-type: none;
    display: flex;
}

.navbar-links li a {
    display: block;
    text-decoration: none;
    color: #707070;
    padding: 10px 25px;
    transition: 0.4s all;
}

.navbar-links li.navbar-dropdown {
    position: relative;
}

.navbar-links li.navbar-dropdown:hover .dropdown {
    visibility: visible;
    opacity: 1;
    transform: translatex(0px);
    z-index: 1111;
}

.navbar-links li.navbar-dropdown .dropdown {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 100%;
    transform: translatex(50px);
    left: 0;
    width: 300px;
    background-color: #fff;
    box-shadow: 0px 10px 10px 3px hsl(0deg 0% 0% / 16%);
    /* border-bottom-left-radius: 3px; */
    /* border-bottom-right-radius: 3px; */
    z-index: 111;
    transition: 0.4s all;
}

.navbar-links li.navbar-dropdown .dropdown a {
    padding-left: 35px;
    padding-top: 22px;
    padding-bottom: 20px;
    font-weight: 400;
    border: 0;
    position: relative;
    text-transform: capitalize;
    color: #222;
}

.navbar-links li.navbar-dropdown .dropdown a:not(:last-child):before {
    content: "";
    position: absolute;
    height: 1px;
    background-color: #c1c1c1;
    width: 75%;
    bottom: 0;
}

.navbar-links li.navbar-dropdown .dropdown a:not(:last-child):after {
    content: "";
    position: absolute;
    height: 1px;
    width: 0%;
    bottom: 0;
    left: 12%;
}

.navbar-links li.navbar-dropdown .dropdown a:not(:last-child):hover:after {
    width: 75%;
}

.navbar-links li:hover > a {
    border-bottom: 5px solid #1870BD;
}

.navbar-links li > a {
    border-bottom: 5px solid transparent;
    font-weight: 600;
    color: white;
    text-transform: capitalize;
    font-size: 18px;
}

.hamburger-icon {
    display: flex;
    align-items: center;
    justify-content: end;
}

.hamburger-icon svg {
    width: 25px;
    height: 25px;
    fill: white;
    margin-right: 20px;
}

header {
    position: absolute;
    padding: 30px 0;
    width: 100%;
    z-index: 111;
}

/* 2. branding-products  */
.branding-products {
    padding-top: 260px;
    padding-bottom: 160px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: -1px;
    min-height: 840px;
}

.home-one-featured-area h1 {
    font-size: 80px;
    color: white;
    text-transform: capitalize;
}

.play svg {
    width: 30px;
    fill: white;
}

.play-button {
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 53px;
}

.play p {
    color: white;
    font-size: 20px;
    padding-left: 43px;
    width: 75%;
    letter-spacing: .5px;
}

.play {
    display: flex;
    align-items: center;
    margin-top: 25px;
}

/* 3. heading */
.heading {
    text-align: center;
    position: relative;
}

.heading p {
    font-weight: bold;
    color: #999;
    letter-spacing: 2px;
    text-transform: uppercase;
    position: relative;
    border-bottom: 3px solid #1870BD;
    line-height: 15px;
    display: inline-block;
    margin-bottom: 10px;
}

.heading img {
    display: block;
    margin: auto;
    padding-bottom: 30px;
}

.heading h2 {
    font-weight: 500;
    width: 60%;
    margin: auto;
    padding-top: 5px;
    padding-bottom: 50px;
}

/* 4. services-style-one */
.services-style-one {
    text-align: center;
}

.services-style-one h4 {
    font-weight: 600;
    padding: 30px 0 15px;
}

.services-style-one-img {
    position: relative;
}

.services-style-one-img:before {
    background-color: #ccf5e5;
    content: "";
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    z-index: -1;
    left: 56%;
    top: -7%;
}

.services-style-one:hover .services-style-one-img:before {
    left: 35%;
    top: 4%;
    height: 100px;
    width: 100px;
}

/* 5. unique */
.shape-style, .shape-style-hover {
    position: relative;
}

.shape-style:before,
.shape-style-hover:before {
    position: absolute;
    content: url(https://ik.imagekit.io/premiumcashbuyers/dots.webp?updatedAt=1686137850495);
    z-index: -1;
    top: -10%;
    left: 30%;
}

.shape-style-hover:before {
    content: url(https://ik.imagekit.io/premiumcashbuyers/dots-hover.webp?updatedAt=1686137850505);
}

.img-unique img {
    width: 100%;
}

.img-unique.shape-style:before {
    top: 88%;
    left: 70%;
}

.unique-solutions .heading {
    text-align: start;
}

.unique-solutions .heading h2 {
    margin: 0;
    width: 80%;
    padding-bottom: 20px;
}

.years-experience {
    display: flex;
    align-items: center;

}

.years-experience h3 {
    font-size: 100px;
    font-weight: 600;
    padding-right: 20px;
}

.unique-solutions > p {
    width: 79%;
    padding-bottom: 40px;
}

/* 6.  how-we-work */
.how-we-work-section .shape-style img {
    width: 100%;
}

.how-we-work-section .shape-style:before {
    top: 90%;
}

.how-we-work h2 {
    font-weight: 600;
    text-transform: capitalize;
}

.planning-make {
    display: flex;
    align-items: self-start;
    margin-top: 60px;
}

.planning-make i {
    padding: 32px;
    border-radius: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c1c1c1;
    margin-right: 60px;
    background-color: white;
}

.planning-make svg {
    width: 56px;
    height: auto;
}

.years-experience h6 {
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
}

.planning-make h3 {
    font-size: 28px;
    font-weight: 600;
}

.planning-make p {
    padding-top: 10px;
    width: 73%;
}

.planning-make {
    position: relative;
}

.planning-make:before {
    position: absolute;
    content: "1";
    height: 35px;
    width: 35px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 31px;
    color: white;
    top: 2%;
}

.planning-make:after {
    position: absolute;
    height: 60px;
    width: 1px;
    content: "";
    background-color: #c1c1c1;
}

.planning-make.two:before {
    content: "2";
}

.planning-make.three:before {
    content: "3";
}

.planning-make.four:before {
    content: "4";
}

.planning-make.five:before {
    content: "5";
}

.planning-make.six:before {
    content: "6";
}

.planning-make.seven:before {
    content: "7";
}

.planning-make.eight:before {
    content: "8";
}

.planning-make.four:after, .planning-make.eight:after {
    height: 0;
}


.planning-make:after {
    position: absolute;
    height: 80px;
    width: 1px;
    content: "";
    background-color: #c1c1c1;
    left: 9%;
    top: 91%;
    z-index: -1;
}

.how-we-work {
    padding-left: 42px;
}

.photo-preview .two img, .photo-preview .one img, .photo-preview .for img, .photo-preview .three img {
    width: 100px;
}

/* 7.  partner */
.partner.item {
    width: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
}

.logodata.owl-carousel .owl-item img {
    width: auto;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.5;
    -webkit-transition: var(--transition);
    transition: var(--transition);
}

.logodata.owl-carousel .owl-item img:hover {
    filter: none;
    opacity: 1;
}

video {
    position: absolute;
    top: 0;
    z-index: -1;
}

/* 8. amazing-team-members */
.amazing-team-members .heading {
    text-align: left;
}

.amazing-team-members .heading h2 {
    margin: 0;
}

.team-one h4 {
    font-size: 26px;
    font-weight: 700;
}

.team-one p {
    width: 74%;
    padding-top: 10 spx;
}

.team-one a.one {
    display: inline-block;
    border-bottom: 3px solid #1870BD;
    line-height: 12px;
    padding-top: 30px;
    margin-bottom: 20px;
}

.team-one a {
    color: #555555;
}

.team-one a i {
    padding-right: 10px;
    font-weight: 20px;
}

.team-flex {
    display: flex;
    padding: 50px;
    border: 1px solid #c5c5c5;
    position: relative;
    margin-top: 40px;
    width: 85%;
    background-color: white;
    margin-bottom: 40px;
}

.img-team img {
    position: absolute;
    right: -17%;
    top: -13%;
}

.team-flex.shape-style-hover:before {
    opacity: 0;
    top: 100%;
    left: 85%;
}

.team-flex.shape-style-hover:hover:before {
    top: 88%;
    left: 85%;
    opacity: 1;
}

.team-flex ul {
    z-index: 111;
    position: absolute;
    display: flex;
    top: 66%;
    left: 58%;
    opacity: 0;
    visibility: hidden;
}

.team-flex:hover ul {
    left: 78%;
    opacity: 1;
    visibility: visible;
}

.team-flex ul li a i {
    color: black;
    background-color: white;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: 12px;
    font-size: 13px;
}

.owl-carousel .owl-item img {
    width: auto;
}

.team-slider.owl-theme .owl-dots {
    display: none;
}

.team-slider .owl-nav {
    position: absolute;
    top: -35%;
    left: 45%;
}

.team-slider .owl-nav button.owl-prev i,
.team-slider .owl-nav button.owl-next i,
.photo .owl-nav button.owl-next i,
.photo .owl-nav button.owl-prev i {
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 51px;
    font-size: 18px;
    border: 1px solid #7d7d7d;
    line-height: 0;
}

.photo.owl-theme .owl-nav [class*=owl-]:hover, .team-slider.owl-theme .owl-nav [class*=owl-]:hover {
    color: #FFF;
    text-decoration: none;
    border-radius: 50px;
    border: none;
}

.amazing-team-members {
    padding-bottom: 80px;
    overflow: hidden;
}

.photo .owl-nav button.owl-next i:hover, .photo .owl-nav button.owl-prev i:hover {
    border: 1px solid #1870BD;
}

/* 9. awwards */
.awwards img {
    padding-bottom: 40px;
}

.awwards h2 {
    font-size: 70px;
    font-weight: 600;
    line-height: 80px;
}

.awwards h6 {
    font-size: 16px;
    font-weight: 700;
}

.awwards {
    border-right: 1px solid #c5c5c5;
    padding-left: 30px;
}

.awwards.end {
    border-right: 0;
}

/*  10. Recent Articles */
.blog-style h6 {
    font-weight: bold;
    font-size: 15px;
    color: #999999;
}

.blog-style {
    border: 1px solid #c5c5c5;
    padding: 84px 40px 40px;
    position: relative;
    background-color: white;
    margin-top: 57px;
}

.blog-style > h3 > a {
    font-weight: 600;
    font-size: 26px;
    padding-bottom: 15px;
    line-height: 36px;
    padding-top: 5px;
}

.blog-style a {
    font-weight: bold;
    font-size: 16px;
    padding-top: 40px;
}

.blog-style a i {
    padding-right: 10px;
}

.blog-style h2 {
    position: absolute;
    font-size: 45px;
    font-weight: 600;
    top: -16%;
    line-height: 33px;
    padding-top: 33px;
    text-align: center;
    height: 120px;
    border-radius: 72px;
    color: white;
    width: 120px;
    align-items: center;
}

.blog-style span {
    font-size: 14px;
    display: block;
}

.blog-style.shape-style-hover:before {
    left: 70%;
    top: 80%;
    opacity: 0;
    visibility: hidden;
}

.blog-style.shape-style-hover:hover:before {
    left: 80%;
    top: 91%;
    opacity: 1;
    visibility: visible;
}

/* 11. footer */
.information-data h3 {
    font-size: 20px;
    color: white;
}

.information-data h6 {
    display: flex;
    font-size: 20px;
    margin-bottom: 20px;
    padding-top: 5px;
}

.information-data > h6 > a {
    color: white;
    font-size: 20px;
    border: 0;
    font-weight: 500;
    padding-left: 7px;
}

.portfolio-two-list .social-media {
    margin-top: 20px;
}

.hire .button-gap {
    margin: auto;
}

.always-happy .information-data > h6 > a {
    padding-left: 8px;
}

.information-data a {
    font-size: 30px;
    color: white;
    border-bottom: 1px solid;
    font-weight: bold;
    display: inline;
}

.newsletter h4 {
    font-size: 20px;
    font-weight: bold;
    color: white;
    border-bottom: 3px solid #1870BD;
    display: inline-block;
}

.newsletter p {
    color: #ddd;
    padding-top: 50px;
    padding-bottom: 20px;
}

.newsletter form input[type="text"]::placeholder {
    color: #ddd;
}

.newsletter form input[type="text"] {
    width: 100%;
    height: 70px;
    padding-left: 30px;
    border: 3px solid #464646;
    outline: none;
    color: #ddd;
    background-color: transparent;
    border-radius: 40px;
    padding-right: 30px;
}

.links {
    padding-top: 20px;
}

.links li {
    padding-top: 30px;
}

.links li a {
    color: #ddd;
    display: inline-block;
    position: relative;
}

.links li a:before {
    position: absolute;
    content: "";
    height: 1%;
    width: 0%;
    background-color: #1870BD;
    bottom: 0;
}

.links li a:hover:before {
    height: 1%;
    width: 100%;
}

ul.links li a i {
    padding-right: 15px;
}

p.footer {
    color: white;
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

p.footer a {
    color: white;
}

p.footer i {
    padding: 0px 7px 0px 7px;
}

.footer-style-one {
    position: relative;
    padding-bottom: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.information, .newsletter, p.portfolio {
    position: relative;
}

.footer-style-one::before {
    top: 0;
    background-color: #000000;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: .5;
}

.newsletter button.sec-btn {
    width: auto;
    margin-top: 10px;
}

.newsletter button.sec-btn:hover {
    color: black !important;
}

.newsletter button.sec-btn::before {
    background-color: white;
}

/* 12. testimonials */
.testimonials h3 {
    font-size: 26px;
    font-weight: bold;
    padding-top: 40px;
}

.testimonials p {
    padding-top: 30px;
    color: black;
}

.testimonials p {
    width: 88%;
    font-size: 26px;
    line-height: 40px;
}

.testimonials h6 {
    font-size: 16px;
    color: #555555;
}

.photo-preview {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    height: 300px;
}

.photo-preview img {
    border-radius: 120px;
}

.photo .owl-dots {
    display: none;
}

.zero img {
    width: 130px;
}

.five img {
    width: 80px;
}

.photo-preview img {
    position: absolute;
}

.photo-preview .zero img {
    left: 20%;
    top: -17%;
}

.photo-preview .two img {
    left: 58%;
    top: 29%;
}

.photo-preview .three img {
    left: 7%;
    top: 94%;
}

.photo-preview .five img {
    left: 26%;
    top: 50%;
}

.photo-preview .one img {
    left: -5%;
    top: 35%;
}

.photo-preview .for img {
    left: 43%;
    top: 97%;
}

.photo-preview::before {
    content: "";
    border: 1px solid #c5c5c5;
    padding: 195px;
    border-radius: 200px;
}

.photo-preview::after {
    content: "";
    border: 1px solid #c5c5c5;
    padding: 187px;
    border-radius: 190px;
    position: absolute;
    left: 1%;
    top: 3%;
    z-index: -1;
}

.photo .owl-nav {
    position: absolute;
}

/* 13. portfolio */
.portfolio .heading h2, .portfolio .heading p {
    color: white;
}

.zoom-slider .owl-item.center > div {
    cursor: auto;
    margin: 0;
}

.zoom-slider .owl-item > div {
    cursor: pointer;
    transition: .30s ease;
    margin-top: 50%;
    padding-left: 1px;
}

.zoom-slider .owl-item > div {
    cursor: pointer;
    transition: .30s ease;
    margin: auto;
    margin-top: 50px;
    padding-right: 40px;
    padding-left: 20px;
}

.item.zoom-slider-img {
    position: relative;
}

.zoom-slider-data h4 {
    color: white;
    margin-top: 24px;
    font-size: 18px;
    letter-spacing: 1px;
}

.zoom-slider-data h2 {
    color: white;
    position: absolute;
    top: 59%;
    left: 0%;
    width: 90%;
    font-weight: 600;
}

.zoom-slider-data h3 {
    color: white;
    font-size: 16px;
    position: relative;
    letter-spacing: 1px;
    margin-left: 30px;
    transform: translateY(-24px);
}

.zoom-slider-data h3:before {
    content: "";
    position: absolute;
    border: 1px solid white;
    padding: 6px;
    border-radius: 15px;
    left: -7%;
}

.zoom-slider .owl-item.active.center .zoom-slider-data h4 {
    opacity: 0;
}

.zoom-slider .owl-item.active.center figure:before {
    content: "";
    position: absolute;
    width: 87.4%;
    height: 90%;
    background-color: #000000;
    opacity: .5;
}

.zoom-slider .owl-item.active.center .zoom-slider-data h2,
.zoom-slider .owl-item.active.center .zoom-slider-data h3 {
    opacity: 1;
}

.zoom-slider .owl-item.active .zoom-slider-data h2,
.zoom-slider .owl-item.active .zoom-slider-data h3 {
    opacity: 0;
}

.zoom-slider button.owl-dot {
    background-color: #555;
    height: 10px;
    width: 25%;
}

.zoom-slider button.owl-dot.active {
    background-color: white;
}

.zoom-slider .owl-dots {
    padding-top: 50px;
}

.zoom-slider.owl-carousel img {
    width: 100%;
}

.zoom-slider .owl-nav {
    position: absolute;
    right: -1%;
    background-color: white;
    display: flex;
    height: 70px;
    width: 70px;
    line-height: 0;
    align-items: center;
    justify-content: center;
    border-radius: 36px;
    bottom: -1%;
}

.zoom-slider button.owl-prev {
    display: block;
    margin-right: 10px;
}

.portfolio {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    padding-bottom: 100px;
}

.portfolio:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: .5;
    top: 0;
}

/* 14. start index 2 */
/*  15 services-style-two  */
.services-style-two img {
    border-radius: 50%;
    width: 100%;
}

.col-lg-4.pr {
    padding-right: 65px;
}

.services-style-two h4 {
    font-weight: bold;
    font-size: 32px;
}

.services-style-two {
    position: relative;
}

.services-style-two ul li a {
    font-size: 16px;
    display: inline-block;
    position: relative;
    color: black;
    z-index: 1;
}

.services-style-two ul li a:before {
    position: absolute;
    width: 0%;
    height: 2px;
    bottom: -7%;
    content: "";
    z-index: -2;
}

.services-style-two ul li a:hover:before {
    width: 100%;
}

.services-style-two-data {
    position: absolute;
    top: 50%;
    left: 39%;
    background-color: #ffffff;
    width: 80%;
    padding: 50px;
}

.services-two {
    padding-bottom: 150px;
}

.services-style-two-data ul {
    padding-top: 10px;
}

.services-style-two-data ul li {
    padding-top: 15px;
}

.services-style-two-data > a {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 36px;
    margin-top: 25px;
    border: 1px solid #d7d7d7;
}

.services-style-two-data > a:hover {
    color: white;
}

.services-style-two figure {
    overflow: hidden;
}

.services-style-two figure:before {
    content: "";
    height: 100%;
    width: 0%;
    position: absolute;
    border-radius: 50%;
    opacity: .0;
}

.services-style-two:hover figure:before {
    opacity: .8;
    width: 100%;
}

/* 16. unique-digital */
.unique-digital-ideas {
    position: relative;
}

.unique-digital-ideas:before {
    position: absolute;
    content: "";
    width: 850px;
    height: 560px;
    bottom: 0;
    z-index: -1;
}

.unique-digital-img {
    display: flex;
    justify-content: center;
}

.unique-digital-ideas .heading {
    text-align: left;
}

.unique-digital-ideas .heading h2 {
    width: 95%;
    margin: 0;
}

/* 17. progress bar */
.submenu {
    display: flex;
    justify-content: center;
    margin: 10px auto 0;
}

.submenu__item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.submenu__item:not(:last-child) {
    margin-right: 30px;
}

.submenu__item a {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    transition-delay: 0s;
    display: inline-flex;
    align-items: center;
    font-size: 17px;
    color: inherit;
    text-decoration: none;
}

.submenu__item a:hover {
    color: #A42CD6;
}

.submenu__item a i {
    color: #A42CD6;
    margin-right: 5px;
}

.content-block {
    margin-top: 50px;
}

.content-block h2 {
    margin-bottom: 30px;
    font-weight: 400;
}

.content-block__effect {
    margin-bottom: 50px;
}

.progress-container {
    margin-bottom: 30px;
}

.play-animation {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    transition-delay: 0s;
    padding: 5px 15px;
    margin: auto;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    border: 2px solid #1c1c1c;
    border-radius: 4px;
    background-color: transparent;
    box-shadow: none;
    outline: none;
    cursor: pointer;
}

.play-animation:hover {
    color: #fff;
    background-color: #1c1c1c;
}

.progress-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.progress-bar {
    position: relative;
    box-sizing: border-box;
}

.progress-bar:not(:last-child) {
    margin-bottom: 35px;
}

.progress-bar--lg-offset:not(:last-child) {
    margin-bottom: 52.5px;
}

.progress-bar__bar,
.progress-bar__bar-inner,
.progress-bar__value,
.progress-bar__skill {
    box-sizing: border-box;
}

.progress-bar__bar {
    position: relative;
    height: 100%;
    width: 100%;
}

.progress-bar__bar--no-overflow {
    overflow: visible;
}

.progress-bar {
    overflow: visible;
    background-color: transparent;
}

.progress-bar__bar-inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transform: scaleX(0);
    transform-origin: left center;
}

.progress-bar__bar-inner.js-animated {
    transition-property: all;
    transition-duration: 1.5s;
    transition-timing-function: cubic-bezier(0.1, 1.14, 0.31, 1.06);
    transition-delay: 0s;
    transform: scaleX(1);
}

.progress-bar__skill, .progress-bar__value {
    font-size: 16px;
    letter-spacing: 0px;
    font-weight: 600;
}

.progress-bar__value--aligned-value {
    transform: translateX(-50%);
}

.progress-bar--1 {
    height: 29px;
    padding: 7px;
    border: 1px solid #1870bd;
    border-radius: 20px;
}

.progress-bar__bar-inner--1 {
    background-image: linear-gradient(to right bottom, #1870bd, #186bb3, #1966a9, #1961a0, #1a5c96);
    background-size: 200%;
    background-position: 0 0;
    border-radius: 8px;
}

.unique-digital-ideas .heading h2 {
    margin-bottom: 20px;
}

.progress-bar__skill--1 {
    position: absolute;
    top: -27px;
    left: -20px;
    opacity: 0;
}

.progress-bar__skill--1.js-animated {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    transition-delay: 0s;
    left: 0;
    opacity: 1;
}

.progress-bar__value--1 {
    position: absolute;
    top: -27px;
    right: -20px;
    opacity: 0;
}

.progress-bar__value--1.js-animated {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    transition-delay: 0s;
    right: 0;
    opacity: 1;
    color: white;
    margin-top: 10px;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    z-index: 11;
    margin-right: -42px;
}

.progress-bar.progress-bar--1 {
    margin-bottom: 70px;
    margin-left: 70px;
}

.unique-digital-ideas .heading {
    text-align: left;
    margin-left: 70px;
}

/* 18. accordion */
.accordion {
    width: 100%;
    max-width: 75rem;
}

.accordion-item {
    position: relative;
    background-color: transparent;
    border: 0;
}

.video-section .heading {
    text-align: left;
}

.video-section .heading p {
    color: white;
}

.video-section .heading h2 {
    color: white;
    margin: 0;
    width: 100%;
    text-align: initial;
}

.accordion-item.active .heading {
    color: #bccbb7;
}

.accordion-item.active .icon:before {
    background: #bccbb7;
}

.accordion-item.active .icon:after {
    width: 0;
}

.accordion-item.active .icon:before {
    background: #ffffff;
}

.accordion-item .heading {
    display: block;
    text-transform: uppercase;
    text-decoration: none;
    color: #7d8c77;
    font-weight: 700;
    font-size: 1rem;
    position: relative;
    padding: 0.5rem 1.5rem 1.5rem 4rem;
    transition: 0.3s ease-in-out;
    padding-bottom: 10px;
}

@media (min-width: 40rem) {
    .accordion-item .heading {
        font-size: 1.2rem;
    }
}

.accordion-item .heading:hover {
    color: #bccbb7;
}

.accordion-item .heading:hover .icon:before, .accordion-item .heading:hover .icon:after {
    background: #000000;
}

.accordion-item .icon {
    display: block;
    position: absolute;
    top: 47%;
    left: 0;
    width: 2rem;
    height: 2rem;
    transform: translateY(-50%);
    background-color: white;
    border-radius: 50%;
}

.accordion-item .icon:before, .accordion-item .icon:after {
    content: "";
    width: 12px;
    height: 3px;
    background: black;
    position: absolute;
    border-radius: 3px;
    left: 50%;
    top: 50%;
    transition: 0.3s ease-in-out;
    transform: translate(-50%, -50%);
}

.accordion-item.active .heading:hover .icon:before {
    background: #ffffff;
}

.accordion-item .icon:after {
    transform: translate(-50%, -50%) rotate(90deg);
    z-index: -1;
}

.accordion-item .content {
    display: none;
}

.accordion-item .content p {
    margin-top: 0;
    color: #cccccc;
    margin-left: 65px;
    width: 75%;
    margin-bottom: 25px;
}

@media (min-width: 40rem) {
    .accordion-item .content {
        line-height: 1.75;
    }
}

.title {
    font-size: 20px;
    color: white;
    text-align: initial;
}

.video-play {
    position: relative;
}

.video-play:before {
    content: url(https://via.placeholder.com/1070x730);
    width: 100%;
    position: absolute;
    height: 100%;
    left: 8.2%;
}

.video-section .heading h2 {
    color: white;
    margin: 0;
    width: 100%;
    padding-bottom: 20px;
    width: 88%;
}

.video-play i {
    font-size: 30px;
    color: white;
    position: absolute;
    left: 60%;
    margin-top: 43%;
    border: 1px solid;
    border-radius: 50%;
    height: 120px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-animation: smallScale 3s infinite;
    animation: smallScale 3s infinite;
}

.video-play i svg {
    fill: white;
    height: auto;
    width: 25px;
}

.video-section {
    min-height: 730px;
}

.video-accordion {
    padding: 102px 0;
}

/* 19. world-leading */
.world-leading .heading {
    text-align: left;
}

.world-leading .heading h2 {
    width: 100%;
    margin: 0;
}

.count-time h2, .count-time span {
    font-size: 90px;
    color: white;
}

.count-time {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px;
    margin: auto;
    height: 260px;
    border-radius: 50%;
    color: white;
    margin-left: 28px;
    flex-wrap: wrap;
}

.count-time {
    position: relative;
}

.count-time p {
    color: white;
    position: absolute;
    top: 64%;
}

.count-time.one {
    position: relative;
    width: 300px;
    height: 300px;
}

.count-time.two {
    margin: 0;
    margin-top: 25px;
}

.count-time.one:before {
    position: absolute;
    content: "";
    height: 80px;
    width: 80px;
    border-radius: 50%;
    top: -33%;
    left: 59%;
}

/* 20. pricing-Plans-style-one */
.pricing-Plans-style-one {
    padding: 40px;
    border: 3px solid #1870BD;
    background-color: white;
}

.pricing-Plans-style-one img {
    display: block;
}

.pricing-Plans-style-one h6 {
    line-height: 20px;
    display: inline-block;
    border-bottom: 3px solid #1870BD;
    padding-top: 30px;
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 500;
}

.pricing-Plans-style-one h3 {
    font-weight: bold;
    font-size: 36px;
    margin-bottom: 20px;
}

.pricing-Plans-style-one span {
    font-size: 16px;
    color: #999;
    text-transform: capitalize;
}

.pricing-Plans-style-one li h4 {
    font-size: 16px;
    text-transform: capitalize;
    padding-top: 25px;
    display: flex;
    align-items: self-end;
    font-weight: bold;
}

.pricing-Plans-style-one li i {
    font-size: 24px;
    padding-right: 20px;
}

.pricing-Plans-style-one li i.red {
    color: red;
}

.pricing-Plans-style-one p {
    padding-top: 30px;
    padding-bottom: 10px;
}

.pricing-Plans-style-one.two {
    border: 3px solid transparent;
}

.pricing-Plans-style-one.two p,
.pricing-Plans-style-one.two h3,
.pricing-Plans-style-one.two h6,
.pricing-Plans-style-one.two h4,
.pricing-Plans-style-one.two span {
    color: white;
}

.pricing-Plans-style-one svg {
    width: 60px;
    height: auto;
    display: block;
    fill: #1870BD;
}

/* 21. create-impactful */
.create-impactful h2 {
    font-size: 60px;
    line-height: 70px;
    font-weight: bold;
}

.create-impactful h3 {
    font-size: 60px;
    line-height: 70px;
}

.create-impactful p {
    font-size: 20px;
    line-height: 30px;
}

.create-impactful .play-button {
    background-image: linear-gradient(90deg, #000000, #000000);
    width: 70px;
    height: 70px;
    margin-left: 20px;
    padding-left: 5px;
}

.hero-section-two {
    position: relative;
}

.hero-section-two:before {
    content: "";
    position: absolute;
    height: 435px;
    width: 720px;
    bottom: 0%;
    right: 0;
}

.hero-section-two-img {
    position: relative;
}

.hero-section-two-img img {
    z-index: 111;
    position: relative;
}

.hero-section-two-img img.two-img-two {
    position: absolute;
    height: 450px;
    left: 30%;
    z-index: 2;
    top: 8%;
}

.hero-section-two-img img.two-img-three {
    position: absolute;
    height: 400px;
    left: 51%;
    z-index: 1;
    top: 13%;
}

.custom2 .owl-nav {
    position: absolute;
    left: -47%;
    bottom: 2%;
    z-index: 1;
}

.custom2.owl-theme .owl-nav [class*=owl-]:hover {
    background-color: transparent;
}

.custom2.owl-theme .owl-nav i:hover {
    background-color: #1870BD;
    color: white;
    border-radius: 40px;
    border: 1px solid #1870BD;
}

.custom2 .owl-nav i {
    background-color: white;
    height: 30px;
    width: 30px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1870BD;
    border: 1px solid #1870BD;
}

.custom2.owl-theme .owl-dots .owl-dot.active span,
.custom2.owl-theme .owl-dots .owl-dot:hover span {
    background: #1870BD;
}

.custom2.owl-theme .owl-dots .owl-dot span {
    width: 110px;
    height: 3px;
    margin: 0;
}

.custom2 .owl-dots {
    position: absolute;
    left: -36%;
    bottom: 4%;
}

.custom2 .owl-nav button.owl-next {
    margin-left: 294px;
    position: absolute;
}

/* 22. blog two home */
.heading.two {
    text-align: left;
}

.heading.two h2 {
    margin: 0;
    width: 100%;
    padding-bottom: 20px;
}

.blog-button p {
    width: 80%;
    padding-bottom: 40px;
}

.blog-two .heading.two h2 {
    width: 94%;
}

.category-info p {
    display: inline-block;
    padding-right: 20px;
    font-weight: 600;
}

.category-info p i {
    padding-right: 10px;
}

.category {
    display: flex;
    align-items: center;
}

.category.one {
    padding-left: 110px;
    padding-bottom: 30px;
}

.category.one img {
    width: auto;
}

.category .category-info {
    margin-right: 10%;
    z-index: 11;
}

.category.one .category-info {
    margin-right: 0%;
}

.category-info > a {
    background-color: #fafafa;
    padding: 8px 22px;
    border-radius: 38px;
    display: inline-block;
    color: black;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.5px;
}

.heading h2 span {
    color: #888;
}

.category-info h3 a {
    background-color: transparent;
    font-weight: 600;
    line-height: 36px;
    padding: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-radius: 0;
    border-bottom: 1px solid #cecece;
}

.category.one.shape-style-hover:before {
    top: 71%;
    left: 90%;
    opacity: 0;
}

.category.shape-style-hover:before {
    top: 79%;
    left: 80%;
    opacity: 0;
    z-index: 1;
}

.category.one.shape-style-hover:hover:before {
    opacity: 1
}

.category.shape-style-hover:hover:before {
    opacity: 1;
}

.category-info {
    padding: 20px 40px;
    background-color: white;
    border: 1px solid #c5c5c5;
    margin-left: -45px;
}

/* 23. client-and-sponsor */
.client-and-sponsor .heading h2 {
    width: 72%;
}

/* 24. footer-style-one.two */
.footer-style-one.two img {
    width: auto;
}

footer.footer-style-one.two .logo p {
    color: #bbbbbb;
    padding-top: 40px;
    width: 90%;
}

.footer-style-one.two .Instagram-img img {
    height: 90px;
    width: 90px;
    object-fit: cover;
}

ul.Instagram-img {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
}

ul.Instagram-img li {
    width: 25%;
    margin-bottom: 10px;
}

.phone {
    display: flex;
    position: relative;
    align-items: center;
}

.phone svg {
    fill: #1870BD;
    width: 55px;
    height: auto;
    margin-right: 20px;
}

.phone a {
    color: white;
}

.phone span {
    color: #bbb;
}

.footer-style-one.two p.footer {
    color: #ffffff;
    z-index: 1;
    position: relative;
    padding-top: 50px;
    border-top: 1px solid #ffffff3d;
    margin-top: 40px;
}

/*video keyframes*/
@-webkit-keyframes smallScale {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.2);
        opacity: 1;
    }
    0% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes smallScale {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.2);
        opacity: 1;
    }
    0% {
        transform: scale(1);
        opacity: 1;
    }
}

/* 25. portfolio img */
.portfolio-img a {
    font-size: 14px;
    color: #555;
    font-weight: 900;
    display: inline-block;
    border-bottom: 1px solid;
    margin-right: 20px;
    text-transform: uppercase;
    padding-top: 20px;
    margin-bottom: 10px;
}

.portfolio-img h3 a {
    font-size: 30px;
    font-weight: 500;
    color: black;
    border: 0;
    line-height: 10px;
    text-transform: capitalize;
}

button.filter-btn {
    border: 0;
}

.filters.portfolio-controllers-container {
    text-align: center;
}

button.filter-btn {
    background-color: transparent;
    color: #999999;
    padding-right: 20px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-left: 20px;
    position: relative;
}

button.filter-btn:before {
    content: "";
    position: absolute;
    border: 1px solid;
    height: 15px;
    width: 15px;
    border-radius: 20px;
    left: -24px;
    top: 3px;
}

.filter-btn.is-checked:before {
    background-color: #1870BD;
    border: 0;
}

.button.filter-btn.active-work.is-checked:before {
    content: "";
    position: absolute;
    background-color: #1870BD;
    height: 15px;
    width: 15px;
    border-radius: 26px;
}

.portfolio-img h3 {
    font-size: 30px;
}

.portfolio-detail {
    margin-top: 40px;
}

ul.client-data {
    display: flex;
    flex-wrap: wrap;
}

ul.client-data li {
    position: relative;
    background-color: #fafafa;
    padding: 45px;
    height: 260px;
    width: 260px;
    border-radius: 50%;
    margin-right: 30px;
}

ul.client-data.partner {
    margin-left: 147px;
    margin-top: -35px;
}

.client a {
    font-size: 16px;
    position: absolute;
    left: 25%;
    top: 0%;
    color: white;
    opacity: 0;
    transform: rotate(90deg) !important;
}

.client:hover img {
    opacity: 0;
}

.client:hover a {
    opacity: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
}

ul.client-data li img {
    padding-top: 22px;
    filter: grayscale(1);
}

.client {
    padding: 40px;
    border-radius: 20px;
    text-align: center;
    position: relative;
}

ul.client-data.partner {
    margin-top: -12px;
}

/* 26. home 3 */
.video-img {
    position: relative;
}

.video-img img {
    width: 100%;
}

.video-img i {
    position: absolute;
    border: 1px solid;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.friendly > img {
    padding: 20px 0;
}

.friendly h6 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 40px;
    text-transform: capitalize;
}

.what-we-provide h2 {
    font-size: 36px;
    font-weight: 500;
    line-height: 46px;
    padding-bottom: 5px;
}

.what-we-provide p {
    width: 90%;
}

.what-we-provide {
    display: flex;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 40px;
    margin-bottom: 40px;
}

.what-we-provide.end {
    margin: 0;
    padding: 0;
    border: 0;
}

.what-we-provide span {
    font-size: 14px;
    letter-spacing: 1px;
    padding-right: 40px;
    color: #999999;
    text-transform: uppercase;
    font-weight: 900;
    margin-top: 5px;
}

/* 27. subscribe-email */
.subscribe-email {
    display: flex;
    align-items: flex-start;
}

.subscribe-email > img {
    padding-right: 30px;
}

.subscribe-email .heading p {
    padding-top: 0;
    color: white;
}

.subscribe-email .heading h2 {
    color: white;
}

.expertise > p {
    width: 71%;
    font-size: 18px;
    color: #cfcfcf;
}

.expertise input[type="text"] {
    width: 60%;
    height: 60px;
    padding-left: 31px;
    border-radius: 49px;
    margin-top: 30px;
    border: 0;
    outline: none;
    color: #666;
    font-size: 15px;
}

.expertise input[type="text"]::placeholder {
    color: #666;
}

/* 28. testimonials */
.testimonials-img {
    text-align: center;
    position: relative;
}

.testimonials-img img {
    border-radius: 50%;
}

.testimonials-img h5 {
    color: black;
    line-height: 30px;
    font-size: 20px;
    font-weight: bold;
    padding-top: 20px;
}

.testimonials-img p {
    font-size: 16px;
    font-weight: 600;
}

.testimonials-img::before {
    top: -20%;
    height: 332px;
    position: absolute;
    content: "";
    width: 1px;
    background-color: #999;
    right: -12px;
}

.we-help {
    text-align: center;
}

.we-help h4 {
    font-size: 26px;
    line-height: 36px;
    font-weight: bold;
}

.we-help p {
    width: 60%;
    margin: auto;
    font-size: 22px;
    line-height: 32px;
    color: black;
    padding: 40px 0 25px;
}

.we-help h6 {
    font-size: 16px;
    color: #555;
}

.clients.owl-carousel .owl-item img {
    width: auto;
    margin: auto;
}

.clients.owl-theme .owl-dots .owl-dot span {
    background: #1870BD;
    height: 5px !important;
    width: 5px !important;
    position: relative;
    padding: 4px;
}

.clients.owl-theme .owl-dots .owl-dot.active span:before {
    content: "";
    border-radius: 10px;
    border: 3px solid #1870BD;
    background-color: transparent;
    height: 18px;
    position: absolute;
    width: 18px;
    top: -5px;
    left: -5px;
}

.clients.owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 30px;
}

/* 29. always-happy */
.always-happy {
    position: relative;
    overflow: hidden;
}

.always-happy .heading.two h2 {
    width: 90%;
    color: white;
}

.always-happy .heading.two p {
    color: white;
    padding-top: 0;
}

section.always-happy.gap {
    background: no-repeat;
}


form.message input[type="text"]::placeholder, textarea::placeholder {
    color: white;
}

form.message input[type="text"], textarea {
    outline: none;
    WIDTH: 100%;
    border: 1px solid #7a7a7a;
    height: 80px;
    padding-left: 20px;
    background-color: transparent;
    color: white;
}

form.message textarea {
    height: 200px;
    padding-top: 20px;
}

span.current {
    padding-left: 5px;
    font-size: 15px;
}

form.message .nice-select.Advice {
    width: 100%;
    background-color: transparent;
    border: 1px solid #7a7a7a;
    height: 80px;
    margin-top: 20px;
    color: white;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-size: 15px;
    border-radius: 0;
}

form.message .nice-select:after {
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    height: 10px;
    right: 30px;
    top: 50%;
    width: 10px;
}

form.message .nice-select.open .list {
    width: 100%;
    color: black;
}

form.message .nice-select:before {
    content: "";
    height: 50px;
    width: 1px;
    background-color: #7a7a7a;
    right: 10%;
    position: absolute;
}

/* 30. digital-marketing */
.digital-marketing {
    position: relative;
}

.digital-marketing {
    position: relative;
}

.digital-marketing a {
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: white;
    padding: 16px 20px;
    font-size: 13px;
    font-weight: bold;
    border-radius: 26px;
    color: black;
    line-height: 0;
}

.digital-marketing a:hover {
    color: white;
    background-color: black;
}

.blog-style-two h2 {
    font-size: 45px;
    color: white;
    background-color: aquamarine;
    text-align: center;
}

.blog-style-two span {
    font-size: 14px;
    display: block;
}

.blog-style-two h2 {
    font-size: 45px;
    color: white;
    color: #FFF;
    text-align: center;
    display: inline-block;
    width: 140px;
    height: 140px;
    border-radius: 83px;
    line-height: 30px;
    padding-top: 36px;
    margin-top: -60px;
    z-index: 1;
    position: relative;
    border: 11px solid white;
}

.digital-marketing img {
    width: 100%;
}

.blog-style-two-data {
    background-color: white;
    padding: 52px;
    padding-top: 0;
    z-index: 1111111;
    position: relative;
}

.blog-style-two-data > h3 > a {
    font-size: 28px;
    font-weight: 600;
    line-height: 38px;
    padding-top: 10px;
    padding-bottom: 20px;
}

.blog-style-two-data a {
    color: black;
    font-size: 16px;
    font-weight: 700;
    padding-top: 50px;
}

.blog-style-two-data a i {
    padding-right: 10px;
}

.blog-style-two.shape-style-hover:before {
    top: 95%;
    left: 80%;
    opacity: 0;
}

.blog-style-two.shape-style-hover:hover:before {
    z-index: 1;
    top: 95%;
    left: 80%;
    opacity: 1;
}

ul.logos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

ul.logos li {
    width: 33.3%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    border: 1px solid #c1c1c1;
    background-color: white;
    margin-left: -1px;
    margin-top: -2px;
}

.subscribe-email {
    position: relative;
    z-index: 111111111;
}

ul.logos li img {
    filter: grayscale(1);
    opacity: .5;
}

ul.logos li:hover img {
    filter: initial;
    opacity: 1;
    transform: scale(1.1);
}

ul.logos.shape-style:before {
    top: 81%;
    left: 80%;
    z-index: -7;
}

.portfolio-data-style {
    position: relative;
}

.style-design a {
    color: black;
    background-color: white;
    font-size: 40px;
    padding: 10px 20px;
    margin-bottom: 10px;
    margin-left: -27px;
}

.style-design span a {
    color: white;
    font-size: 18px;
    padding: 5px 10px;
    display: inline;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
}

.portfolio-data-style:hover .style-design {
    position: absolute;
    bottom: 12px;
    left: 0%;
    opacity: 1;
}

.style-design {
    position: absolute;
    bottom: 4%;
    left: -25%;
    opacity: 0;
}

/* 31. Duke*/

figure.effect-duke img,
figure.effect-duke p {
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
}

figure.effect-duke {
    background: -webkit-linear-gradient(-45deg, black 0%, black 100%);
    background: linear-gradient(-45deg, black 0%, black 100%);
}

figure.effect-duke:hover img {
    opacity: 0.8;
    -webkit-transform: scale3d(1.2, 1.2, 1);
    transform: scale3d(1.2, 1.2, 1);
}

figure.effect-duke .style-design {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 20px;
    text-transform: none;
    font-size: 90%;
    opacity: 0;
    -webkit-transform: scale3d(0.8, 0.8, 1);
    margin: 0;
}

/* 32. Media queries */
@media screen and (max-width: 50em) {
    .content {
        padding: 0 10px;
        text-align: center;
    }

    .grid figure {
        display: inline-block;
        float: none;
        margin: 10px auto;
        width: 100%;
    }
}

/* 33. home 4 /

/* 34. layout of the columns */
.cols {
    width: 100%;
    clear: both;
}

.cols .tile {
    height: 100%;
    float: left;
    overflow: hidden;
    font-size: 24px;
}

.cols.two .tile {
    width: 50%;
}

.cols.three .tile {
    width: 33%;
}

.cols.three .tile:last-of-type {
    width: 34%;
}

.cols.four .tile {
    width: 25%;
}

.rows {
    height: 100%;
}

.rows.two .cols {
    height: 50%;
}

.rows.three .cols {
    height: 33%;
}

.rows.three .cols:last-of-type {
    height: 34%;
}

.rows.four .cols {
    height: 25%;
}

.cols .tile {
    background-size: cover;
}

.cols .tile.schmancy {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    padding: 40px;
}

.tile .content {
    position: relative;
    top: 50%;
    left: 50%;
    width: 50%;
    margin-left: -25%;
    color: black;
}

.schmancy.tile img {
    width: 240px;
    height: 140px;
}

.schmancy.tile.pic0 img, .schmancy.tile.pic2 img, .schmancy.tile.pic1 img, .schmancy.tile.pic3 img {
    display: none;
}

.schmancy.tile.pic0:hover img, .schmancy.tile.pic2:hover img,
.schmancy.tile.pic1:hover img,
.schmancy.tile.pic3:hover img {
    margin-left: 43%;
    display: block;
}

.tile {
    align: center;
}

.cols .tile {
    transition: 1s;
}

.section-portfolio {
    padding-bottom: 165px;
}

.cols .tile:hover {
    opacity: 1;
    font-size: 40px;
}

.cols.two:hover .tile {
    width: 20%;
}

.cols.two:hover .tile:hover {
    width: 80%;
}

.cols.three:hover .tile {
    width: 20%;
}

.cols.three:hover .tile:hover {
    width: 60%;
}

.cols.four:hover .tile {
    width: 20%;
}

.cols.four:hover .tile:hover {
    width: 40%;
}

.rows .cols {
    transition: 1s;
}

.rows.two:hover .cols {
    height: 20%;
}

.rows.two:hover .cols:hover {
    height: 80%;
}

.rows.three:hover .cols {
    height: 20%;
}

.rows.three:hover .cols:hover {
    height: 60%;
}

.rows.four:hover .cols {
    height: 20%;
}

.rows.four:hover .cols:hover {
    height: 40%;
}

.friendly.for-page p {
    font-size: 18px;
    line-height: 30px;
}

.friendly.for-page > img {
    padding-top: 40px;
}

.design-agency .what-we-provide span {
    font-size: 28px;
}

.design-agency .what-we-provide p {
    width: 100%;
}

.design-agency .what-we-provide {
    justify-content: space-between;
}

.friendly.for-page p span {
    font-weight: bold;
    text-decoration: underline;
}

/* 35. footer 4 */
.start-footer {
    text-align: center;
}

.start-footer a {
    color: black;
}

footer.three img {
    width: auto;
}

.start-footer .information-data a {
    color: black;
    border-bottom: 1px solid black;
}

.start-footer .information-data h6 {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 40px;
}

.start-footer .information-data h6 a {
    border: 0;
}

.start-footer ul {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
}

.start-footer ul li a {
    color: black;
    height: 60px;
    width: 60px;
    border-radius: 40px;
    border: 1px solid;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 18px;
}

.start-footer ul li a:hover {
    border: 1px solid #1870BD;
    color: white;
}

.start-footer ul li {
    padding-right: 10px;
}

.start-footer h2 {
    padding: 65px 0 35px;
    font-size: 60px;
    font-weight: 600;
}

.start-footer p.footer {
    color: black;
    align-items: center;
    justify-content: center;
    display: flow-root;
}

.start-footer span a {
    border-bottom: 3px solid #000;
    display: inherit;
}

.behance ul {
    display: flex;
    flex-wrap: wrap;
}

.behance ul li figure.effect-duke {
    width: 100px;
}

.behance li {
    width: 30%;
}

.portfolio-page-img-data.video-img i svg {
    fill: white;
    width: 22px;
}

.portfolio-page-img-data.video-img {
    margin-top: 47px;
}

.behance.Instagram li {
    width: 16%;

}

.behance.Instagram li img {
    width: 100px;
    height: 100px;
}

.behance ul {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
}

.behance.Instagram li {
    width: 16.6%;
    margin-bottom: 10px;
}

.behance.Instagram h3 a {
    font-size: 26px;
    color: black;
    display: inline-block;
    margin-top: 10px;
    border-bottom: 1px solid;
}

.behance h2 {
    font-size: 70px;
    border-bottom: 3px solid;
    display: inline-block;
    font-weight: 500;
    margin-bottom: 40px;
}

.behance li img {
    width: 180px;
    height: 140px;
    object-fit: cover;
}

/* 36. our blog */
.hero-section {
    padding: 260px 0 60px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
}

.hero-section:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-color: black;
    top: 0;
    opacity: .4;
}

.hero-section h2 {
    font-size: 80px;
    font-weight: 600;
    color: white;
    text-transform: capitalize;
    position: relative;
}

.hero-section p {
    color: white;
    width: 60%;
    position: relative;
}

.hero-section ul {
    display: flex;
    align-items: center;
    position: relative;
}

.hero-section ul li i {
    color: black;
    padding: 6px;
    border-radius: 30px;
    background-color: white;
    margin-right: 10px;
    font-size: 12px;
    width: 25px;
    height: 25px;
}

.hero-section ul li {
    padding-right: 18px;
    display: flex;
    align-items: center;
}

.hero-section ul li a {
    color: white;
    font-size: 14px;
    border-bottom: 2px solid #8b8a8e;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 13px;
}

.hero-section p {
    color: white;
    width: 43%;
    padding-top: 20px;
    padding-bottom: 60px;
    font-size: 20px;
    line-height: 30px;
}

.pagination {
    display: flex;
    margin-top: 60px;
}

ul.pagination li {
    padding: 20px;
    border: 1px solid #c1c1c1;
    height: 70px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

ul.pagination li.prev {
    width: 100px;
}

ul.pagination li:hover {
    border: 1px solid #1870BD;
}

ul.pagination li:hover a {
    color: white;
}

ul.pagination li a {
    font-size: 16px;
    font-weight: 700;
}

/* 37. error-page */
.error-page h2 {
    font-size: 300px;
    font-weight: 400;
    color: white;
    line-height: 200px;
}

.error-page {
    background-size: cover;
    background-position: center;
}

.error-page {
    padding-top: 260px;
    padding-bottom: 120px;
    text-align: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.error-page h3 {
    font-size: 45px;
    font-weight: bold;
    color: white;
}

.error-page p {
    color: white;
    margin-bottom: 25px;
    font-size: 20px;
}

.page-not-found form input[type="text"] {
    width: 100%;
    height: 60px;
    border-radius: 45px;
    padding-left: 25px;
    border: 0;
    outline: none;
    margin-bottom: 20px;
}

.page-not-found form {
    display: flex;
    align-items: center;
    position: relative;
}

.page-not-found form button {
    color: black;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 44px;
    border: 1px solid #ccc;
    background-color: transparent;
    position: absolute;
    right: 1%;
    top: 7%;
}

/* 38. blog post */
.blog-post-img {
    position: relative;
}

.blog-post-img a {
    position: absolute;
    top: 8%;
    left: 5%;
    background-color: white;
    color: black;
    padding: 10px 25px;
    border-radius: 40px;
    line-height: 20px;
    font-weight: 600;
    font-size: 13px;
}

.blog-post-img a:hover {
    color: white;
    background-color: black;
}

.blog-post-data {
    padding: 60px;
    border: 1px solid #c1c1c1;
    border-top: 0;
    margin-bottom: 40px;
    background-color: white;
}

.blog-post-data.end {
    margin-bottom: 0;
}

.blog-post-data h2 {
    font-size: 45px;
    font-weight: 600;
    text-transform: capitalize;
}

.blog-post-data ul li {
    display: flex;
    align-items: center;
}

.blog-post-data ul li i {
    margin-right: 10px;
}

.blog-post-data ul li h6 {
    font-size: 16px;
    color: #555;
    font-weight: 600;
}

.blog-post-data img {
    border-radius: 40px;
    margin-right: 10px;
}

.blog-post-img img {
    width: 100%;
}

.blog-post-data ul li {
    display: flex;
    align-items: center;
    padding-right: 20px;
}

.blog-post-data p {
    line-height: 32px;
}

.blog-post-data ul {
    display: flex;
    align-items: center;
    border: 1px solid #f0f0f0;
    border-left: 0;
    border-right: 0;
    padding: 10px 0;
    margin: 30px 0;
}

.blog-post {
    background-color: white;
}

.blog-post.shape-style-hover:before {
    top: 95%;
    left: 88%;
    opacity: 0;
}

.blog-post.shape-style-hover:hover:before {
    top: 96.5%;
    left: 90.5%;
    opacity: 1;
}

.recent-post li {
    display: flex;
    align-items: center;
}

.recent-post a {
    font-size: 18px;
    font-weight: bold;
    color: black;
}

.recent-post i {
    padding-right: 10px;
    font-size: 20px;
}

.sidebar h3 {
    font-size: 28px;
    border-bottom: 3px solid #1870BD;
    display: inline-block;
    line-height: 20px;
    margin-bottom: 25px;
    font-weight: 500;
}

.sidebar {
    border: 1px solid #c1c1c1;
    padding: 40px;
    margin-bottom: 20px;
}

.recent-post img {
    margin-right: 20px;
}

ul.recent-post span {
    padding-top: 10px;
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: #555555;
}

ul.recent-post li {
    padding-bottom: 20px;
}

ul.recent-post li.end {
    padding: 0;
}

ul.categories li a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fafafa;
    padding: 6px;
    border-radius: 49px;
    color: black;
    font-size: 16px;
    font-weight: 600;
    padding-left: 30px;
}

ul.categories li a span {
    background-color: white;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 29px;
}

ul.categories li a:hover {
    color: white;
}

ul.categories li a:hover span {
    color: black;
}

ul.categories li {
    padding-bottom: 5px;
}

ul.quick-links li a {
    color: #555555;
    font-size: 16px;
    font-weight: 600;
}

ul.quick-links li {
    padding-bottom: 30px;
}

ul.quick-links li.end {
    padding-bottom: 0px;
}

.quick-links i {
    color: #c5c5c5;
    padding-right: 10px;
}

ul.Meta li a {
    font-weight: 600;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #c5c5c5;
    color: #555555;
}

ul.Meta li.end a {
    padding: 0;
    margin: 0;
    border: 0;
}

.tags-cloud a {
    font-size: 16px;
    font-weight: bold;
    border: 1px solid #cfcfcf;
    color: black;
    display: inline-block;
    padding: 15px 22px;
    position: relative;
    overflow: hidden;
}

.tags-cloud a:before {
    position: absolute;
    content: "";
    width: 5%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

.tags-cloud a:hover {
    color: white;
}

.tags-cloud a:hover:before {
    width: 100%;
}

ul.tags-cloud {
    display: flex;
    flex-wrap: wrap;
}

ul.tags-cloud li {
    margin-right: 10px;
}

.sidebar.sidebar-two h3 {
    color: white;
}

.sidebar.sidebar-two p {
    color: #cfcfcf;
    padding-bottom: 15px;
}

.sidebar.sidebar-two {
    border: 0;
    margin: 0;
    background-size: cover;
}

.sidebar-two input[type="text"] {
    width: 100%;
    height: 60px;
    padding-left: 25px;
    border-radius: 50px;
    border: 0;
    outline: none;
}

/* 39. mobile app */
.mobile-app a {
    display: inline-block;
    font-weight: 900;
    font-size: 13px;
    color: #555555;
    margin-right: 10px;
    border-bottom: 1px solid;
}

.mobile-app h2 {
    font-size: 50px;
    font-weight: 600;
    padding-top: 20px;
}

.client-detail li {
    border-bottom: 1px solid #e1e1e1;
    padding-top: 35px;
    padding-bottom: 25px;
}

.client-detail li.end {
    border-bottom: 0;
    padding-top: 35px;
}

.client-detail span {
    font-size: 16px;
    color: black;
    display: block;
}

.client-detail a {
    font-size: 18px;
    font-weight: bold;
    color: black;
    position: relative;
    display: inline-block;
}

.client-detail h4 {
    font-size: 18px;
    font-weight: bold;
    color: black;
    padding-top: 4px;
}

.client-detail a:before {
    position: absolute;
    content: "";
    height: 1px;
    width: 0;
    bottom: 0;
    background-color: black;
}

.client-detail a:hover:before {
    width: 100%;
}

.social-media {
    display: flex;
}

.social-media li {
    margin-right: 20px;
}

.social-media li a i {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #555555;
    border-radius: 40px;
    color: #555555;
    margin-right: 10px;
}

.social-media li a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #555555;
}

.mobil-img {
    text-align: end;
}

.mobil-img.shape-style-hover:before {
    top: 93.5%;
    left: 85%;
    opacity: 0;
}

.mobil-img.shape-style-hover figure.effect-duke {
    height: 660px;
}

.mobil-img.shape-style-hover figure.effect-duke img {
    object-fit: cover;
}

.mobil-img.shape-style-hover:hover:before {
    top: 95.5%;
    left: 87%;
    opacity: 1;
}

.detailmobil {
    padding-top: 40px;
}

.detailmobil h4 {
    font-size: 30px;
    border-bottom: 3px solid #1870BD;
    display: inline-block;
    margin-bottom: 30px;
}

.detailmobil p {
    font-size: 18px;
    line-height: 30px;
}

.detailmobil span {
    text-decoration: underline;
    font-weight: bold;
}

ul.features li {
    position: relative;
    padding-bottom: 20px;
    font-size: 18px;
    padding-left: 25px;
}

ul.features li:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 0;
    top: 15%;
}

ul.features.provide li {
    padding-left: 0;
}

ul.features.provide li:before {
    display: none;
}

.features.provide i {
    padding-right: 15px;
}

ul.features li.end {
    padding-bottom: 0;
}

.portfolio-page-img-data.video-img i {
    left: 50%;
    top: 56%;
    transform: translate(-50%, -50%);
}

/* 40. portfolio-page 2 */
.portfolio-page-img-data.video-img.shape-style-hover:before {
    top: 94%;
    left: 92.5%;
    opacity: 0;
}

.portfolio-page-img-data.video-img.shape-style-hover:hover:before {
    top: 96%;
    left: 93.5%;
    opacity: 1;
}

.portfolio-page-img-data.video-img.shape-style-hover {
    margin-top: 0;
}

.portfolio-two-list {
    padding: 40px;
    padding-top: 0;
    border: 3px solid #1870BD;
}

.client-help {
    border: 3px solid #1870BD;
    padding: 50px;
    margin-top: 60px;
    position: relative;
    overflow: hidden;
}

.client-help:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 10px;
    left: 0;
    top: 0;
}

.client-help p {
    font-size: 26px;
    line-height: 40px;
    padding-bottom: 10px;
}

.client-help a {
    font-weight: bold;
    font-size: 26px;
    color: black;
}

.client-help span {
    font-size: 16px;
}

.portfolio-page-img.details-three {
    padding: 60px 0;
}

.blog-style-two.blog-three p {
    font-size: 18px;
    line-height: 30px;
}

/* 41. services page */
.services-img p {
    width: 80%;
}

.our-expertise {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.our-expertise li {
    display: flex;
    width: 33%;
    align-items: center;
    border: 1px solid #c1c1c1;
    padding: 30px 40px;
    position: relative;
    margin-left: -1px;
    margin-top: -1px;
}

.our-expertise li a {
    font-size: 18px;
    font-weight: 600;
}

.our-expertise li a i {
    padding-right: 10px;
}

.our-expertise li:hover a {
    color: white;
}

.our-expertise li:before {
    content: "";
    position: absolute;
    width: 0%;
    height: 100%;
    left: 0;
    z-index: -1;
}

.our-expertise li:hover:before {
    width: 100%;
}

.services-three .services-style-one {
    padding: 60px;
    background-color: white;
}

.services-three .services-style-one-img img {
    position: relative;
}

.services-style-one-img svg {
    width: 105px;
    z-index: 11111111;
    position: relative;
}

.services-three .services-style-one-img:before {
    z-index: 0;
}

.servics-clients .we-help {
    text-align: start;
    padding: 65px;
}

.d-flex-service {
    display: flex;
    align-items: center;
}

.clients.servics-clients.owl-carousel .owl-item img {
    width: auto;
    margin: 0;
}

.clients.servics-clients.owl-theme .owl-nav.disabled + .owl-dots {
    position: absolute;
    margin: 0;
    left: 86%;
    top: 80%;
}

.servics-clients .we-help h4 {
    color: white;
}

.servics-clients .we-help p {
    width: 100%;
    margin: auto;
    color: white;
    font-size: 26px;
    line-height: 36px;
    padding: 0;
    padding-bottom: 37px;
}

.clients .owl-nav {
    position: absolute;
    top: 40%;
}

.clients .owl-nav {
    position: relative;
}

.clients .owl-nav {
    position: absolute;
}

.servics-clients .we-help h6 {
    color: white;
}

.d-flex-service img {
    padding: 12px;
    border-radius: 66%;
    margin-right: 20px !important;
}

.we-provide .title {
    color: black;
}

.we-provide .accordion-item .icon {
    background-color: #fafafa;
}

.we-provide .accordion-item .content p {
    color: #555;
    margin-bottom: 20px;
}

.we-provide .video-accordion {
    padding: 0;
}

.portrait-camra {
    text-align: end;
}

.portrait-camra img.img-gap {
    display: block;
    margin-top: -40px;
    margin-left: 40px;
}

/* 42. blog detalis */
.blog-style-two.blog-three:hover {
    box-shadow: 11px 16px 59px -36px rgba(0, 0, 0, 0.0);
    -webkit-box-shadow: 11px 16px 59px -36px rgba(0, 0, 0, 0.0);
    -moz-box-shadow: 11px 16px 59px -36px rgba(0, 0, 0, 0.0);
}

.blog-style-two.blog-three h2 {
    margin-left: 40px;
}

.blog-style-three-data ul {
    display: flex;
    align-items: center;
    padding-top: 10px;
}

.blog-style-three-data ul li {
    display: flex;
    align-items: center;
    padding-right: 20px;
}

.blog-style-three-data .blog-post-data ul li h6 {
    font-size: 16px;
    color: #555;
    font-weight: 600;
}

.blog-style-three-data img {
    border-radius: 40px;
    margin-right: 10px;
}

.blog-style-two p span {
    display: inline;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid;
}

.blog-style-three-data h3 a {
    color: black;
    font-size: 40px;
    font-weight: 600;
}

.blog-style-two.blog-three .client-help p {
    font-size: 26px;
    line-height: 40px;
    padding-bottom: 10px;
}

/* 43. comment */
.comment {
    padding-top: 60px;
}

.comment h2, .comment h1 {
    display: inline-block;
    font-size: 40px;
    font-weight: 600;
    border-bottom: 5px solid #1870BD;
    line-height: 40px;
}

.comment-data h4 {
    font-size: 24px;
    font-weight: bold;
    display: inline;
}

.comment-data span {
    color: #555;
    font-size: 16px;
    font-weight: bold;
}

.comment li {
    display: flex;
    background-color: #f1f1f1;
    padding: 15px;
    position: relative;
    margin-left: 30px;
    margin-top: 30px;
}

.comment-data {
    padding: 35px;
    padding-left: 0;
}

.comment li img {
    position: relative;
    left: -5%;
    object-fit: cover;
}

.comment li a {
    color: white;
    background-color: black;
    position: absolute;
    right: 2%;
    font-weight: 600;
    padding: 4px 12px;
    font-size: 14px;
    text-transform: uppercase;
}

li.reply-comment {
    margin-left: 103px;
    width: 89%;
    background-color: transparent;
    border: 1px solid #f1f1f1;
}

form.leave input, form.leave select {
    width: 100%;
    height: 60px;
    padding-left: 25px;
    border-radius: 42px;
    border: 1px solid #c1c1c1;
    margin-top: 20px;
    outline: none;
}

form.leave select:focus {
    box-shadow: none !important;
    border: 1px solid #c1c1c1 !important;
    outline: 0 !important;
}

form.leave input, form.leave input::placeholder, form.leave select {
    color: #555;
}

form.leave textarea {
    width: 100%;
    height: 180px;
    border-radius: 30px;
    margin-top: 20px;
    border: 1px solid #c1c1c1;
    outline: none;
    color: #555;
    padding-top: 25px;
}

form.leave textarea::placeholder {
    color: #555;
}

.share-post {
    display: flex;
    align-items: center;
}

.share-post h5 {
    font-size: 20px;
    font-weight: 600;
    padding-right: 20px;
}

.share-post {
    display: flex;
    align-items: center;
    padding: 25px 40px;
    border: 1px solid #c1c1c1;
    margin-top: 40px;
}

.social-media li:hover a i {
    color: white;
    background-color: #1870BD;
    border: 1px solid #1870BD;
}

.willimes-marko {
    display: flex;
    align-items: center;
    margin-top: 40px;
    padding: 50px;
    padding-left: 170px;
    position: relative;
    width: 95%;
    margin-left: 46px;
    padding-right: 20px;
}

.willimes-marko img {
    position: absolute;
    left: -5%;
}

.social-media-Intege h4 {
    font-size: 26px;
    font-weight: 700;
    color: white;
}

.social-media-Intege {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.willimes-marko p {
    width: 93%;
    color: white;
}

.social-media-Intege .social-media li {
    margin-right: 5px;
}

.social-media-Intege .social-media li a i {
    background-color: white;
    border: 0;
    color: #555;
    margin-right: 0px;
}

.social-media-Intege .social-media li a i:hover {
    color: white;
}

.blog-style-two.blog-three.page-two .digital-marketing a {
    position: relative;
    background-color: #fafafa;
    display: inline-flex;
    left: 0;
    margin-bottom: 16px;
    padding: 22px;
    letter-spacing: .5px;
}

.blog-style-two.blog-three.page-two .blog-style-three-data {
    padding: 0px 140px;
}

.blog-style-two.blog-three.page-two .blog-style-three-data h3 a {
    font-size: 50px;
    width: 90%;
    margin-top: 30px;
}

.blog-style-three-data .blog-post-data {
    border: 0;
    padding: 0;
}

.blog-style-three-data .blog-post-data ul {
    margin: 0;
    margin-top: 15px;
}

.pdd {
    padding: 0 140px;
}

.pdd .willimes-marko img, .pdd .comment li img {
    left: -4%;
}

/* 44. tabes services */
button span {
    color: #999;
    font-weight: bold;
    padding-right: 15px;
    font-size: 18px;
}

button.active span {
    color: white;
}

.nav-pills .nav-link {
    color: black;
    font-size: 18px;
    font-weight: bold;
    border-radius: 0;
}

.nav-pills .nav-link {
    color: black;
    font-size: 18px;
    font-weight: bold;
    text-align: start;
    border: 1px solid #c1c1c1;
    padding: 20px;
    margin-bottom: 15px;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: transparent;
}

.services-style-pag-img.shape-style-hover:before {
    left: 88.5%;
    top: 92%;
    opacity: 0;
}

.services-style-pag-img.shape-style-hover:hover:before {
    left: 90.5%;
    top: 94%;
    opacity: 1;
}

.services-style-pag h2 {
    padding-top: 40px;
    font-size: 40px;
    font-weight: bold;
}

.services-style-pag p span {
    font-weight: 700;
    border-bottom: 1px solid;
    margin-left: 5px;
}

/* 45. team page */
.colin-clickson > a {
    font-weight: 600;
    border-bottom: 3px solid #1870BD;
    display: inline;
    color: #555;
}

.our-skills h6 {
    border-bottom: 3px solid #1870BD;
    font-size: 16px;
    font-weight: 900;
    color: #999;
    display: inline-block;
    margin-bottom: 60px;
    text-transform: uppercase;
}

.colin-clickson h6 a {
    font-weight: 600;
    display: inline;
    color: #555;
}

.colin-clickson h4 {
    font-size: 30px;
    font-weight: bold;
    padding-bottom: 10px;
}

.colin-clickson p {
    padding-bottom: 34px;
    font-weight: 500;
}

.colin-clickson h6 i {
    padding-right: 10px;
    color: #555;
}

.colin-clickson .social-media li {
    margin-right: 0;
    margin-top: 30px;
}

.colin-clickson .social-media li a i {
    height: 60px;
    width: 60px;
    font-size: 20px;
}

.colin-clickson {
    border-right: 1px solid #c1c1c1;
}

.our-skills .progress-bar__skill--1.js-animated {
    font-size: 14px;
    font-weight: bold;
    margin-top: -6px;
}

.our-skills .progress-bar__bar {
    position: relative;
    height: 100%;
    width: 100%;
}

.our-skills .progress-bar__bar-inner--1 {

}

.our-skills .progress-bar--1 {
    height: 20px;
    padding: 0px;
    border: 0px solid #dddddd;
    border-radius: 42px;
    background-color: white;
    margin: 0;
    margin-top: 60px;
}

.our-skills .progress-bar__value--1.js-animated {
    background-image: linear-gradient(90deg, #00bd5300, #00dea500);
    color: white;
    margin-top: 0;
    height: 36px;
    width: 38px;
    border-radius: 0px;
    z-index: 11;
    margin-right: 0px;
    margin-top: -10px;
}

.team-man.shape-style-hover:before {
    top: 91.5%;
    left: 75%;
    opacity: 0;
}

.team-man.shape-style-hover:hover:before {
    top: 93.5%;
    left: 77%;
    opacity: 1;
}

.our-expertise-time {
    padding-top: 60px;
}

/* 46. about */
.about-unique .services-style-one {
    padding: 35px;
    border: 1px solid #c1c1c1;
    margin-bottom: 20px;
}

.about-unique .unique-solutions > p {
    width: 94%;
}

.about-unique .services-style-one {
    padding: 60px 40px;
    border: 1px solid #c1c1c1;
    margin-bottom: 20px;
}

.about-business.unique-digital-ideas:before {
    display: none;
}

.pricing-Plans {
    background-size: cover;
    background-position: center;
}

.client-and-sponsor.about-client {
    padding-top: 60px;
}

.planning-make.about-make:after {
    display: none;
}

.planning-make.about-make {
    text-align: center;
    display: block;
    padding-top: 70px;
    background-color: white;
    z-index: 0;
    margin: 0;
    padding-bottom: 40px;
    border: 1px solid #c1c1c1;
    border-top: 0;
    margin-left: -1px !important;
}

.planning-img-team {
    margin-left: -1px !important;
}

.designing-projects {
    box-shadow: 0px 0px 41px 0px rgba(0, 0, 0, 0.11);
    -webkit-box-shadow: 0px 0px 41px 0px rgba(0, 0, 0, 0.11);
    -moz-box-shadow: 0px 0px 41px 0px rgba(0, 0, 0, 0.11);
}

.planning-make.about-make p {
    width: 80%;
    margin: auto;
}

.planning-make.about-make i {
    margin: auto;
    margin-bottom: 20px;
    background-color: white;
    position: absolute;
    left: 36%;
    top: -32%;
    z-index: -1;
}

.planning-make.about-make:before {
    left: 35%;
    top: -31%;
}

.planning-make.about-make:before {
    left: 36%;
}

/* 47. content */
form.content-form input[type="text"], form.content-form input[type="email"] {
    width: 100%;
    border-radius: 52px;
    height: 60px;
    padding-left: 30px;
    margin-bottom: 20px;
    border: 1px solid #c1c1c1;
    color: #555;
    outline: none;
}

form.content-form input[type="text"]::placeholder, form.content-form input[type="email"]::placeholder {
    color: #555;
}

form.content-form textarea {
    width: 100%;
    height: 160px;
    border-radius: 32px;
    border: 1px solid #c1c1c1;
    padding-top: 20px;
    color: #555;
    margin-bottom: 15px;
}

form.content-form textarea::placeholder {
    color: #555;
}

form.content-form h5 {
    font-size: 16px;
    color: #555;
    padding-bottom: 5px;
}

.address i {
    font-size: 40px;
    padding-right: 30px;
}

.what-we-provide.address h4 {
    font-size: 14px;
    color: #999;
    font-weight: 800;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-bottom: 20px;
    padding-top: 13px;
}

.what-we-provide.address h2 a {
    font-size: 30px;
    font-weight: 500;
    line-height: 34px;
}

.what-we-provide.address h5 {
    font-size: 18px;
}

.what-we-provide.address {
    padding-bottom: 25px;
    margin-bottom: 25px;
}

.what-we-provide.end.address {
    padding: 0;
    margin: 0;
}

.map iframe {
    width: 100%;
    height: 530px;
    filter: invert(100);
}

.location-data {
    padding: 40px;
    border: 1px solid #c1c1c1;
    background-color: white;
    margin-top: 30px;
}

.location-data h3 {
    font-weight: 500;
    margin-bottom: 40px;
    text-transform: capitalize;
    border-bottom: 4px solid #1870BD;
    display: inline-block;
    line-height: 34px;
}

.location-data a {
    padding-bottom: 10px;
    position: relative;
    display: table;
}

.location-data a:before {
    content: "";
    position: absolute;
    height: 1px;
    width: 0%;
    bottom: 12px;
}

.location-data a:hover:before {
    width: 100%;
}

.location-data.shape-style-hover:before {
    left: 78%;
    top: 85%;
    opacity: 0;
}

.location-data.shape-style-hover:hover:before {
    left: 80%;
    top: 86%;
    opacity: 1;
}

.myslider.owl-theme .owl-dots .owl-dot span {
    background-color: transparent;
}

.footer-style-one.two ul.links {
    width: 100%;
    flex-wrap: wrap;
}

.footer-style-one.two ul.links li {
    width: 50%;
}

.footer-style-one.two ul.links {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
}

/* 48. transition */
.blog-style.shape-style-hover:before, .links li a:before, .services-style-two ul li a:before,
.services-style-two figure:before, .recent-post a, ul.categories li a, .our-expertise li a, .featured-area-for-data a,
.team-flex ul, .team-flex, .team-flex.shape-style-hover:before, .tags-cloud a, .social-media li a i,
.logodata.owl-carousel .owl-item img, .services-style-one-img:before, .client-help, a.button-style, ul.Meta li a,
.category.shape-style-hover:before, .shape-style-hover:before, .client-detail a:before, .blog-style a, .blog-style-three-data h3 a,
.category.one.shape-style-hover:before, .blog-style-two-data a, .tags-cloud a:before, .mobil-img.shape-style-hover:before,
.category.shape-style-hover .category-info, .blog-style-two, ul.logos li img, .services-style-one h4 a,
.category-info h3 a, .schmancy.tile.pic0 img, ul.logos li, .start-footer ul li a, .blog-post,
ul.pagination li:hover, .what-we-provide.address h2 a, .location-data a:before, .location-data a, ul.quick-links li a,
header.header-style-three.fore .hamburger-icon a, .start-footer span a:hover, .planning-make h3, .blog-post-img a,
.social-media-Intege .social-media li a i, .services-style-pag-img.shape-style-hover:before, .phone a, .client-help a,
.team-flex ul li a i, .category-info > a, .portfolio-img h3 a, .information-data a, .digital-marketing a, .hamburger-icon a,
.navbar-links li.navbar-dropdown .dropdown a:not(:last-child):after, .mobile-nav > ul > li > a:after, .comment li a,
.portfolio-page-img-data.video-img.shape-style-hover:before, .our-expertise li:before, .client a, .blog-post-data h2,
.location-data, .portfolio-data-style .style-design, .cls-left ul li a:before, .phone-style a, .clients .owl-nav button i {
    transition: .3s ease-in-out;
}

@media (max-width: 2000px) and (min-width: 1400px) {
    .container {
        max-width: 1400px;
    }
}

/* 49. box-shadow */
.team-flex:hover, .blog-style:hover, .category.shape-style-hover:hover .category-info, .client-help:hover,
.blog-style-two:hover, ul.logos, ul.logos li:hover, .blog-post:hover, .portfolio-two-list, .share-post,
.willimes-marko, .location-data:hover {
    box-shadow: 11px 16px 59px -36px rgba(0, 0, 0, 0.34);
    -webkit-box-shadow: 11px 16px 59px -36px rgba(0, 0, 0, 0.34);
    -moz-box-shadow: 11px 16px 59px -36px rgba(0, 0, 0, 0.34);
}

/* 50. background-image color */
.blog-style h2, .services-style-two figure:before, .unique-digital-ideas:before, .willimes-marko,
.accordion-item.active .icon, .count-time, .count-time.one:before, .blog-style-two h2, .client:hover,
ul.categories li a:hover, .tags-cloud a:before, .our-expertise li:before, .d-flex-service img,
.progress-bar__value--1.js-animated,
.nav-pills .nav-link.active, .nav-pills .show > .nav-link, a.button-style, ul.pagination li:hover,
ul.ks-cboxtags li input[type="checkbox"]:checked + label, .play-button, .hero-section-two:before {
    background-image: linear-gradient(to right bottom, #1870bd, #1669b1, #1562a5, #135b9a, #12548e);
}

.sec-btn {
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff !important;
    height: 60px;
    background-image: linear-gradient(to right bottom, #1870bd, #186bb3, #1966a9, #1961a0, #1a5c96);
    -moz-box-shadow: 5px 5px 30px 0px rgba(195, 120, 251, 0.35);
    display: inline-block;
    overflow: hidden;
    padding: 18px 50px;
    border-radius: 30px;
    position: relative;
    z-index: 1;
    letter-spacing: 0.5px;
    transition: all 0.2s cubic-bezier(0.98, 0.57, 1, 1) 0s;
    font-weight: 700;
}

.sec-btn::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    background: #1A5C96;
    transition: all 0.2s cubic-bezier(0.98, 0.57, 1, 1) 0s;
    transform: scale(0.2, 1);
}

.category-info a:hover {
    background-color: #1A5C96;
    color: white;
}

.category-info h3 a:hover {
    background-color: transparent;
}

.sec-btn:hover::before {
    opacity: 1;
    transform: scale(1, 1);
    transition: all 0.2s cubic-bezier(0.98, 0.57, 1, 1) 0s;
}

.sec-btn:hover {
    transition: all 0.2s cubic-bezier(0.98, 0.57, 1, 1) 0s;
}

button.sec-btn {
    border: 0;
}

.button-gap {
    text-align: center;
    margin-top: 40px;
}

.sec-btn i {
    padding-right: 10px;
}

.blog-post-data .sec-btn {
    margin-top: 30px;
}

.sidebar.sidebar-two button.sec-btn {
    margin-top: 10px;
}

.pricing-Plans-style-one button.sec-btn {
    width: 100%;
}

.pricing-Plans-style-one.two button.sec-btn:before {
    background-color: white;
}

.pricing-Plans-style-one.two button.sec-btn:hover {
    color: black !important;
}

/* 51. cboxtags */
ul.ks-cboxtags {
    list-style: none;
    padding-bottom: 20px;
}

ul.ks-cboxtags li {
    display: inline;
}

ul.ks-cboxtags li label {
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.9);
    border: 3px solid #1870BD00;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    border-radius: 25px;
    white-space: nowrap;
    margin: 3px 0px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    transition: all 0.2s;
    background-color: #f1f1f1;
}

ul.ks-cboxtags li label {
    padding: 10px 20px;
    cursor: pointer;
}

ul.ks-cboxtags li label::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 12px;
    padding: 2px 6px 2px 2px;
    content: "\f067";
    transition: transform 0.3s ease-in-out;
}

ul.ks-cboxtags li input[type="checkbox"]:checked + label::before {
    content: "\f00c";
    transform: rotate(-360deg);
    transition: transform 0.3s ease-in-out;
}

ul.ks-cboxtags li input[type="checkbox"]:checked + label {
    border: 2px solid #1870BD;
    color: #fff;
    transition: all 0.2s;
}

ul.ks-cboxtags li input[type="checkbox"] {
    display: absolute;
}

ul.ks-cboxtags li input[type="checkbox"] {
    position: absolute;
    opacity: 0;
}

/* 52. scroll */
#scroll {
    position: fixed;
    right: 20px;
    bottom: 10px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    background-color: #1870BD;
    text-indent: -9999px;
    display: none;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    border-radius: 60px;
    animation: bounce2 12s ease infinite;
}

@keyframes bounce2 {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

#scroll span {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -8px;
    margin-top: -12px;
    height: 0;
    width: 0;
    border: 8px solid transparent;
    border-bottom-color: #ffffff;
}

#scroll:hover {
    background-color: #000;
    opacity: 1;
    filter: "alpha(opacity=100)";
    -ms-filter: "alpha(opacity=100)";
    box-shadow: 0px 0px 20px #fff;
}

/* 53. pricing-plan-one */
.package {
    padding: 40px;
}

.package h3 {
    font-size: 35px;
    font-weight: bold;
    color: white;
}

.package span {
    font-size: 16px;
}

.package h6 {
    font-size: 18px;
    color: white;
    padding-top: 20px;
    padding-bottom: 15px;
}

.pricing-plan-one li {
    border: 1px solid #c1c1c1;
    display: flex;
    align-items: center;
    font-size: 30px;
    padding: 25px;
    justify-content: center;
    background-color: white;
    border-top: 0;
}

.pricing-plan-one {
    margin-left: -1px;
}

.pricing-plan-one li h4 {
    font-size: 16px;
    font-weight: 700;
    display: none;
}

.pricing-plan-one li i.red {
    color: red;
}

.package svg {
    height: auto;
    width: 60px;
    fill: white;
}

.pricing-plan-one a.sec-btn {
    width: 100%;
    display: flex;
    align-items: center;
    height: 80px;
    border-radius: 0;
    justify-content: center;
    padding: 0;
}

.pricing-plan-one.drak a.sec-btn {
    background: linear-gradient(90deg, black 0%, black 100%);
}

.pricing-plan-one.drak .sec-btn::before {
    background: linear-gradient(90deg, #00bd53, #00dea5)
}

.pricing-plan-one .package {
    background-color: #00e088;
}

.pricing-plan-one.text-style .package, .pricing-plan-one.text-style a.sec-btn {
    opacity: 0;
    visibility: hidden;
}

.pricing-plan-one.text-style ul li {
    justify-content: start;
}

.pricing-plan-one.text-style ul li {
    justify-content: start;
    border-left: 0;
    border: 0;
}

.pricing-plan-one.text-style ul li:not(:last-child) {
    border-bottom: 1px solid #c1c1c1;
}

.pricing-plan-one.text-style li {
    padding: 30.5px;
}

.pricing-plan-one.text-style li i {
    display: none;
}

.pricing-plan-one.text-style li h4 {
    display: block;
}

.pricing-plan-style {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.custom1 .owl-dots {
    counter-reset: dots;
}

.custom1 .owl-dots {
    position: absolute;
    top: 90%;
    left: 12%;
}

.custom1.owl-theme .owl-dots .owl-dot span {
    display: none;
}

.custom1 .owl-dot:before {
    counter-increment: dots;
    content: counter(dots);
    color: white;
    top: 0px;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 45px;
    margin-right: 10px;
    line-height: 0;
    width: 35px;
    height: 35px;
    padding-top: 3px;
}

.custom1 .owl-dot.active:before {
    background-color: white;
    color: black;
    border: 1px solid white;
}

/* 54. mobil nav */
.mobile-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 33%;
    height: 100%;
    background-color: black;
    padding: 40px 80px 15px;
    z-index: 5;
    transition: 0.45s ease-in-out;
    transform: translateX(-101%);
    -webkit-transform: translateX(-101%);
    -moz-transform: translateX(-101%);
    -o-transform: translateX(-101%);
    overflow-y: auto;
}

.mobile-nav ul li a svg {
    width: 11px;
}

.for #nav-icon4 {
    color: black;
}

#nav-icon4 span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
    top: 13px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon4 span:nth-child(3) {
    top: 25px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon4.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -3px;
    left: 8px;
}

#nav-icon4.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 35px;
    left: 8px;
}

i#nav-icon4 {
    display: none;
}

#nav-icon4 {
    width: 40px;
    height: 25px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    display: none;
    font-size: 25px;
    color: white;
}

#nav-icon4 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.cart-popup {
    position: absolute;
    right: 130px;
    top: 78px;
    width: 340px;
    background-color: #fff;
    padding: 25px 25px 20px;
    transform: translateY(50px);
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -o-transform: translateY(50px);
    opacity: 0;
    visibility: hidden;
    box-shadow: 2px 3px 35px 0px rgb(0 0 0 / 10%);
    -webkit-box-shadow: 2px 3px 35px 0px rgb(0 0 0 / 10%);
}

.cart-popup:before {
    content: "";
    width: 41%;
    height: 5px;
    position: absolute;
    left: 59.2%;
    top: -5px;
}

.show-cart {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
}

.cart-popup .cart-btns a:not(:last-child) {
    margin-right: 8px;
}

.cart-popup .cart-btns a:hover {
    color: white;
}

.mobile-nav > ul > li.menu-item-has-children:before {
    content: "";
    background-image: url(https://ik.imagekit.io/premiumcashbuyers/down-arrow.svg?updatedAt=1686137850456);
    background-repeat: no-repeat;
    position: absolute;
    top: 26px;
    left: 95%;
    width: 15px;
    height: 15px;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
}

.mobile-nav > ul > li.menu-item-has-children.active:before {
    background-image: url(https://ik.imagekit.io/premiumcashbuyers/down-arrow-clr.svg?updatedAt=1686137850494);
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
}

.mobile-nav > ul > li:not(:last-child) {
    position: relative;
    padding: 18px 0;
    display: block;
}

.mobile-nav > ul > li {
    position: relative;
    padding: 18px 0;
    display: block;
}

.mobile-nav > ul li > a {
    font-size: 18px;
    color: white;
    line-height: 0px;
    font-family: "Mont";
    display: inline-block;
    text-transform: capitalize;
    margin-bottom: 24px;
}

.mobile-nav > ul > li > a {
    line-height: 36px;
    color: white;
    font-size: 30px;
    font-family: "Mont";
    text-transform: capitalize;
    font-weight: 400;
    display: inline-block;
    position: relative;
    margin-bottom: 0;
}

.mobile-nav > ul li a:hover:after,
.mobile-nav > ul li > a:hover:after {
    width: 100%;
}

.mobile-nav > ul > li.menu-item-has-children > ul.sub-menu {
    padding-left: 20px;
    position: absolute;
    height: 0;
    opacity: 0;
    visibility: hidden;
    transform: translateX(15px);
    -webkit-transform: translateX(15px);
    -moz-transform: translateX(15px);
    -o-transform: translateX(15px);
    margin-top: 8px;
}

.mobile-nav > ul > li.menu-item-has-children.active > ul.sub-menu {
    position: relative;
    height: auto;
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    transition-delay: 0.1s;
}

.mobile-nav > ul > li.menu-item-has-children.active > ul.sub-menu li {
    padding: 5px 0;
    position: relative;
    display: table;
}

.res-log {
    margin-bottom: 30px;
}

.mobile-nav .donation {
    display: flex;
    align-items: center;
    margin: 25px 0;
}

.mobile-nav .donation a:first-child {
    margin-right: 40px;
}

.mobile-nav .donation a.theme-btn {
    padding: 10px 30px;
}

.mobile-nav .donation a.cart {
    position: relative;
}

.mobile-nav .donation a.cart:before {
    content: "1";
    color: white;
    width: 25px;
    height: 25px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    top: -7px;
    right: -19px;
    z-index: 1;
    font-size: 13px;
}

.res-rights p {
    color: #d6d6d6;
    font-weight: bold;
    letter-spacing: 5px;
    margin-top: 30px;
}

.mobile-nav.open {
    transform: translateX(0) !important;
    z-index: 3333;
    box-shadow: 3px 3px 39px -4px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 3px 3px 39px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 3px 3px 39px -4px rgba(0, 0, 0, 0.75);
    padding: 40px;
}

li.menu-item-has-children.active {
    background-color: transparent;
}

#mobile-nav.hmburger-menu {
    width: 33%;
    z-index: 1111;
    background-color: black;
    padding: 40px;
}

.res-cross {
    position: absolute;
    right: 55px;
    top: 15px;
    width: 40px;
}

.mobile-nav .cls-leftright {
    height: 4px;
    width: 40px;
    position: absolute;
    margin-top: 18px;
    background-color: #fff;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all .3s ease-in;
    -ms-transition: all .3s ease-in;
    -o-transition: all .3s ease-in;
    transition: all .3s ease-in;
}

.mobile-nav .cls-rightleft {
    height: 4px;
    width: 40px;
    position: absolute;
    margin-top: 18px;
    background-color: #fff;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: all .3s ease-in;
    -ms-transition: all .3s ease-in;
    -o-transition: all .3s ease-in;
    transition: all .3s ease-in;
}

.mobile-nav .res-cross:hover .cls-leftright {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.mobile-nav .res-cross:hover .cls-rightleft {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

ul.Contacts.mobil {
    margin-top: 60px;
}

ul.Contacts.mobil a {
    display: flex;
    font-size: 18px;
    color: white;
    text-transform: capitalize;
}

ul.Contacts.mobil li {
    margin-top: 0px;
}

.bar-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bar-menu svg {
    fill: white;
    width: 35px;
    height: 35px;
    display: none;
}

.header-style {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* 55. menu-inner */
body.active .menu-inner {
    visibility: visible;
    opacity: 1;
    right: 0;
    width: 100%;
}

body.menu-layer:before {
    content: "";
    transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
    -webkit-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
    -moz-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
    -o-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
    transform: scale(0, 0) translate(50%, -50%);
    -moz-transform: scale(0, 0) translate(50%, -50%);
    -webkit-transform: scale(0, 0) translate(50%, -50%);
    -o-transform: scale(0, 0) translate(50%, -50%);
    -ms-transform: scale(0, 0) translate(50%, -50%);
    -webkit-transition-delay: 0.25s;
    -moz-transition-delay: 0.25s;
    -o-transition-delay: 0.25s;
    -ms-transition-delay: 0.25s;
    transition-delay: 0.25s;
    display: block;
    width: 370vw;
    height: auto;
    padding-top: 370vw;
    background-color: #000;
    position: fixed;
    top: 0;
    right: 0;
    -ms-transform-origin: 100% 0%;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    border-radius: 50%;
    z-index: 2;
    opacity: .9;
}

body.menu-layer.active:before {
    transform: scale(2, 1) translate(50%, -50%);
    -moz-transform: scale(2, 1) translate(50%, -50%);
    -webkit-transform: scale(2, 1) translate(50%, -50%);
    -o-transform: scale(2, 1) translate(50%, -50%);
    -ms-transform: scale(2, 1) translate(50%, -50%);
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -o-transition-delay: 0s;
    -ms-transition-delay: 0s;
    transition-delay: 0s;
    z-index: 111;
}

.menu-cls-btn {
    position: absolute;
    width: 40px;
    height: 40px;
    cursor: pointer;
    right: 40px;
    top: 40px;
    z-index: 9;
}

.cls-leftright {
    height: 4px;
    width: 40px;
    position: absolute;
    margin-top: 18px;
    background-color: #fff;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all .3s ease-in;
    -ms-transition: all .3s ease-in;
    -o-transition: all .3s ease-in;
    transition: all .3s ease-in;
}

.cls-rightleft {
    height: 4px;
    width: 40px;
    position: absolute;
    margin-top: 18px;
    background-color: #fff;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: all .3s ease-in;
    -ms-transition: all .3s ease-in;
    -o-transition: all .3s ease-in;
    transition: all .3s ease-in;
}

.menu-cls-btn:hover .cls-leftright {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.menu-cls-btn:hover .cls-rightleft {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.menu-bar nav > ul > li.menu-item-has-children > a {
    position: relative;
}

.menu-bar nav > ul > li.menu-item-has-children > a:before {
    content: "";
    background-image: url(https://ik.imagekit.io/premiumcashbuyers/down-arrow.svg?updatedAt=1686137850456);
    background-repeat: no-repeat;
    position: absolute;
    top: 53%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    right: 0px;
    width: 10px;
    height: 10px;
}

.menu-inner {
    position: fixed;
    right: -45%;
    top: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    z-index: 999;
    transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
    -webkit-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
    -moz-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
    -o-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
    -webkit-transition-delay: 0.4s;
    -moz-transition-delay: 0.4s;
    -o-transition-delay: 0.4s;
    -ms-transition-delay: 0.4s;
    transition-delay: 0.4s;
    width: 32%;
    padding: 40px;
    height: 100%;
}

.menu-inner {
    background-color: #111111;
}

.contact-info h5 {
    font-size: 16px;
    width: 92%;
    line-height: 26px;
    padding: 20px 0 10px;
}

.menu-wrap .contact-map iframe {
    height: 300px;
    margin-top: 20px;
    border-radius: 20px;
}

.menu-inner .contact-info a {
    font-size: 18px;
    font-weight: 500;
}

canvas.particles-js-canvas-el {
    position: absolute;
    top: 2%;
    left: 0%;
    opacity: .3;
    z-index: 0;
}

.button.download.button--moema.button--text-thick.button--text-upper.button--size-s:hover {
    background: linear-gradient(90deg, #4219dc, #973af1);
    border: 0;
}

.custom1 {
    position: relative;
}

.view-btn {
    text-align: end;
    position: absolute;
    right: 12%;
    bottom: 6%;
}

.view-btn h5 {
    color: white;
    font-weight: 600;
}

.view-btn i {
    margin-left: 15px;
    color: black;
    background-color: white;
    width: 30px;
    line-height: 0;
    height: 30px;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    border-radius: 26px;
    font-size: 16px;
}

.menu-inner.ps.ps--active-x.ps--active-y {
    display: flex;
    padding: 100px;
}

.menu-inner.ps.ps--active-x.ps--active-y .portfolio-img a {
    color: white;
    padding: 0;
    margin: 5px;
    line-height: 22px;
    display: table-cell;
    padding-right: 20px;
    padding-top: 25px;
    padding-bottom: 12px;
    border: 0;
}

.menu-inner.ps.ps--active-x.ps--active-y .portfolio-img h3 {
    color: white;
}

.marquee_text {
    font-size: 6rem;
    font-weight: bold;
    line-height: 7rem;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.marquee_text .portfolio-img {
    display: table-caption;
    padding: 20px;
}

.js-marquee {
    display: flex;
    margin-right: 0 !important;
}

.marquee_text .portfolio-img img {
    width: 370px;
    height: 470px;
    object-fit: cover;
}

.marquee_text .portfolio-img h3 a {
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 40px;
}

.marquee_text .portfolio-img a {
    font-weight: 500;
    font-size: 14px;
}

.marquee_text .portfolio-img span {
    font-size: 18px;
    display: block;
    margin-top: -30px;
}

.hero-section-two, .video-section {
    overflow: hidden;
}

.menu-inner.ps.ps--active-x.ps--active-y .portfolio-img h3 a {
    padding-top: 0;
}

form.message button.sec-btn {
    margin-top: 20px;
}

form.message button.sec-btn:hover {
    color: black;
}

form.message .sec-btn:hover {
    color: black !important;
}

form.message button.sec-btn::before {
    background: white;
}

/* 56. lightbox */
.lightbox-toggle {
    color: black;
}

.box {
    z-index: 2111111111111;
    position: fixed;
    opacity: 1;
    display: none;
    top: 0;
    width: auto;
    height: auto;
    background: #000;
    padding: 50px;
    height: 100%;
    width: 100%;
    padding: 120px 400px;
}

.box * {
    z-index: 3;
}

.close i {
    position: absolute;
    font-size: 22px;
    background-color: black;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    right: 0%;
    top: 0%;
}

.close i {
    position: absolute;
    font-size: 30px;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    right: 1%;
    top: 2%;
}

.flex-img h2 {
    font-size: 48px;
    margin-bottom: 50px;
    color: white;
    text-align: center;
}

/* 57. overlay */
.textright {
    float: right;
    position: absolute;
    right: 6%;
    top: 2%;
}

.white_content {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 50%;
    height: 100%;
    padding: 60px 100px;
    background-color: white;
    z-index: 1002;
    overflow: hidden;
}

#overlay {
    background-color: #ffffffd4;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 111;
}

.cls-left h6 {
    display: none;
}

.cls-left ul li a {
    font-size: 18px;
    padding-bottom: 12px;
    color: black;
    position: relative;
    display: inline;
}

.white_content i {
    font-size: 35px;
    position: absolute;
    top: 5%;
}

.cls-left ul li {
    padding-bottom: 20px;
}

.cls-left ul li a:before {
    content: "";
    position: absolute;
    height: 1px;
    background-color: black;
    bottom: 33%;
    width: 0;
}

.cls-left ul li a:hover:before {
    width: 100%;
}

.menu-grid {
    display: flex;
}

.cls-left.cls-right {
    margin-right: 10%;
    border-right: 1px solid #cbc7c370;
    padding-right: 80px;
}

.owl-item.active .home-one-featured-area h1, .owl-item.active .create-impactful h3 {
    animation-duration: 1s;
    animation-name: fadeInOne;
}

.owl-item.active .play, .owl-item.active .create-impactful h2 {
    animation-duration: 1.5s;
    animation-name: fadeInOne;
}

.owl-item.active .create-impactful p {
    animation-duration: 1.8s;
    animation-name: fadeInOne;
}

@keyframes fadeInOne {
    0% {
        opacity: 0;
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
    }
    40% {
        opacity: 0;
        -webkit-transform: translateY(80px);
        transform: translateY(80px);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.hire h2 {
    padding: 0;
}

.cls-left.cls-right h6 {
    display: block;
}

.cls-left.cls-right h3 {
    font-size: 40px;
    line-height: 50px;
    font-weight: 600;
}

.cls-left.cls-right h6 {
    display: block;
    font-size: 34px;
}

.cls-left.cls-right .colin-clickson {
    border: 0;
}

.cls-left.cls-right .colin-clickson h5 {
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
}

.box form.message .nice-select:before {
    right: 7%;
}

header .sec-btn:before {
    background: white;
}

header .sec-btn:hover {
    color: black !important;
}

.we-help svg {
    width: 70px;
    height: auto;
}

/* 58. header style two */
.phone-style svg {
    width: 30px;
    fill: #1870BD;
    height: auto;
}

.phone-style a {
    font-size: 14px;
    color: #555;
}

.phone h6 {
    font-size: 16px;
    color: #fff;
}

.phone-style h4 {
    font-size: 14px;
    padding-left: 6px;
    font-weight: 600;
    padding-right: 8px;
}

header.header-styletwo {
    position: relative;
    padding: 0;
}

.top-bar-header, .end-bar {
    padding: 10px 0;
}

.content-style {
    display: flex;
    align-items: center;
    padding-right: 20px;
}

.phone-style {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.top-bar-header .social-media li a i {
    background-color: white;
    border: 0;
    height: 35px;
    width: 35px;
}

header.header-styletwo .navbar-links li > a {
    color: black;
}

header.header-styletwo .hamburger-icon svg, header.header-styletwo .bar-menu svg {
    fill: black;
}

header.header-styletwo .sec-btn:before {
    background: black;
}

header.header-styletwo .sec-btn:hover {
    color: white !important;
}

/* 59. featured img three */
.featured-img {
    text-align: end;
    display: flex;
    align-items: center;
    justify-content: end;
}

.featured-img img.featured-one-img {
    margin-right: 30px;
}

.myslider .item {
    height: auto;
    width: 100%;
}

.myslider .owl-dots .owl-dot {
    margin: 0px 5px;
}

.myslider .owl-dots .owl-dot button {
    background: none;
    border: none;
    padding: 0;
    color: #999;
    font-size: 32px;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 40px;
    margin-left: 20px;
}

.myslider .owl-dots .owl-dot button:focus {
    outline: none;
}

.myslider .owl-dots .owl-dot.active button {
    color: #ffffff;
}

.featured-area-three {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 160px 0;
}

.featured-area-three .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 10px;
    display: inline-flex;
    position: absolute;
    left: -52%;
    top: 20%;
    width: 42%;
    flex-wrap: wrap;
}

.myslider.owl-theme .owl-dots .owl-dot span, .myslider .owl-theme .owl-dots .owl-dot:hover span {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    background-color: transparent;
}

.myslider .owl-dots .owl-dot button span {
    font-size: 18px;
    position: absolute;
    margin-top: 14px;
    left: 0;
}

.myslider .owl-dots .owl-dot.active button span {
    color: var(--color-theme);
}

.myslider .owl-dots {
    top: 4%;
    display: flex;
    position: absolute;
    left: -52%;
    width: 40%;
    flex-wrap: wrap;
}

.myslider .owl-nav {
    position: absolute;
    left: -53%;
    display: flex;
}

.myslider .owl-nav button.owl-prev, .myslider .owl-nav button.owl-next {
    color: white;
    line-height: 0;
    border: 1px solid;
    width: 30px;
    height: 30px;
    border-radius: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.myslider .owl-nav button.owl-prev:hover, .myslider .owl-nav button.owl-next:hover {
    color: white;
    border: 1px solid #1870BD;
    background-color: #1870BD;
}

.featured-area-three .view-btn {
    bottom: 7%;
}

.hamburger-icon svg {
    display: block;
}

.hamburger-icon a {
    color: white;
}

header.header-style-three.fore .hamburger-icon a {
    color: black;
}

header.header-style-three.fore .hamburger-icon svg {
    fill: black;
}

header.header-style-three.fore .sec-btn:before {
    background-color: black;
}

header.header-style-three.fore .sec-btn:hover {
    color: white !important;
}

header.header-style-three .hamburger-icon svg {
    width: 34px;
    height: 33px;
    margin-left: 30px;
    display: block;
}

.featured-img img {
    object-fit: cover;
}

.team-flex ul li a i:hover {
    color: white;
    background-color: var(--color-theme);
}

.team-slider.owl-theme .owl-nav [class*=owl-]:hover {
    background-color: var(--color-theme);
}

.team-slider .owl-nav button.owl-prev i:hover, .team-slider .owl-nav button.owl-next i:hover {
    border: 1px solid #1870BD;
}


/* 61 MULTI SPINNER */

.multi-spinner-container {
    width: 150px;
    height: 150px;
    position: relative;
    margin: 30px auto;
    overflow: hidden;
}

.multi-spinner {
    position: absolute;
    width: calc(100% - 9.9px);
    height: calc(100% - 9.9px);
    border: 5px solid transparent;
    border-top-color: #fff;
    border-radius: 50%;
    -webkit-animation: spin 5s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
    animation: spin 3s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.team-slider-threuue .colin-clickson.team-sttle-two .our-skills {
    margin-top: 45px;
}

.team-slider-threuue .colin-clickson.team-sttle-two {
    padding: 58px;
    background-color: white;
    border: 0;
}

.clients .owl-dots {
    margin-top: 30px;
}

.clients .owl-nav {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.clients .owl-nav button i {
    font-size: 18px;
    border: 1px solid #ddd;
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.clients .owl-nav button i:hover {
    border: 1px solid #1870BD;
    background-color: #1870BD;
    color: white;
}

.clients.owl-theme .owl-nav [class*=owl-]:hover {
    background-color: transparent;
    color: black;
}

.clients.servics-clients .owl-nav {
    display: none;
}

.clients.servics-clients .owl-dots {
    position: absolute;
    left: 83%;
    bottom: 16%;
}

.input-wrap {
    position: relative;
    overflow: hidden;
}

.input-wrap:before {
    position: absolute;
    content: "";
    background-color: #7a7a7a;
    height: 18px;
    width: 22px;
    right: -8px;
    transform: rotateZ(45deg);
    top: -13%;
}

.input-wrap.textarea:before {
    top: -4%;
}

.input-wrap.nice {
    margin-top: 20px;
}

.box .nice-select.Advice {
    margin-top: 15px;
}

.video-img svg {
    width: 24px;
    fill: white;
}

.portfolio-page-img .portfolio-page-img-data.video-img {
    margin-top: 0;
}

ul.Instagram-img figure.effect-duke {
    width: 90px;
    height: 90px;
}

.featured-area-for-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #c1c1c1;
    padding-bottom: 30px;
}

.featured-area-for-data a {
    font-size: 40px;
    font-weight: 600;
    text-transform: capitalize;
}

.featured-area-for-data.end {
    border-bottom: 0px solid #c1c1c1;
    padding-bottom: 0px;
}

.portfolio-resposive-for {
    display: none;
}

.blog-style-three-data ul li i {
    padding-right: 10px;
}

.blog-style-two.blog-three.page-two .digital-marketing a:hover {
    background-color: black;
}

.services-page .heading h2 {
    width: 90%;
}

.location-data h5 a {
    color: black;
}

.address i svg {
    width: 36px;
    height: auto;
    fill: #1870BD;
}

.about-unique .years-experience {
    margin-bottom: 30px;
}

sup {
    font-size: .60em;
}